import {
  useGetUsersAllQuery,
  useGetWorkflowsQuery,
  usePostJobsMutation,
} from 'api/orchestrationApi';
import { RootState } from 'app/store';
import { FC, MutableRefObject, useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import PaginatorContainer from '../../components/PaginatorContainer/PaginatorContainer';
import StatusContainer from '../../components/StatusContainer/StatusContainer';
import TableActionsContainer from '../../components/TableActionsContainer/TableActionsContainer';
import TableActionsLeft from '../../components/TableActionsLeft/TableActionsLeft';
import TableActionsRight from '../../components/TableActionsRight/TableActionsRight';
import TableContainer from '../../components/TableContainer/TableContainer';
import { TABLE_COLUMNS } from './Dashboard.constants';
import { IDashboardState } from 'interfaces/dashboard/dashboard-slice.interface';
import { GridApi, RowNode } from 'ag-grid-enterprise';
import {
  ORCHESTRATION_STATUSES,
  PAGE,
  SSE_EVENTS,
  SSE_STATUS,
} from 'utils/common-constants';
import { DateUtils } from 'utils/dateUtils/DateUtils';
import { IOrchestrationRow } from 'interfaces/dashboard/orchestration-row.interface';
import style from './Dashboard.module.scss';
import { useServerSideEvents } from 'utils/hooks/useSSE';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import {
  SelectFilterInfoType,
  selectFilterStatus,
  selectOrchestrations,
} from 'app/dashboard/DashboardSlice';
import { PAGE_OPTIONS } from 'interfaces/table.interface';
import { history } from 'utils/common-constants';
import { useLocation, useNavigate } from 'react-router-dom';

const Dashboard: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const dashboardStatus: IDashboardState = useAppSelector(
    (state: RootState) => state.dashboardContainer
  );
  const filterInfo = useAppSelector<SelectFilterInfoType>(selectFilterStatus);
  const orchestrationsState: IOrchestrationRow[] =
    useAppSelector(selectOrchestrations);
  const [loadDashboardInfo] = usePostJobsMutation();
  const CLOCK_ICON_LABEL = 'clock';
  (history.navigate as any) = useNavigate();
  (history.location as any) = useLocation();
  useGetUsersAllQuery({});
  useGetWorkflowsQuery({});
  const { connectionStatusState } = useServerSideEvents(
    [
      SSE_EVENTS.JOB_CREATED,
      SSE_EVENTS.JOB_STATUS_UPDATE,
      SSE_EVENTS.JOB_STEP_UPDATE,
      SSE_EVENTS.JOB_COUNTERS_UPDATE,
    ],
    PAGE.ORCHESTRATION_STATUS
  );

  useEffect(() => {
    if (connectionStatusState === SSE_STATUS.RESTORED) {
      getInfo();
    }
  }, [connectionStatusState]);

  const getInfo = useCallback(() => {
    loadDashboardInfo(filterInfo || {});
  }, [dispatch, filterInfo]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  const updateDurationColumn = (
    gridApiRef: MutableRefObject<GridApi | null>,
    orchestrations: IOrchestrationRow[]
  ) => {
    orchestrations.forEach((orchestration) => {
      if (orchestration?.status === ORCHESTRATION_STATUSES.IN_PROGRESS) {
        const rowNode = gridApiRef.current?.getRowNode(
          orchestration.id.toString()
        );
        setTimeout(() => getOrchestrationDuration(orchestration, rowNode), 100);
        (function increaseTimeloop() {
          const increaseTimeInterval = setInterval(() => {
            const rowInDocument = document.querySelectorAll(
              `[row-id="${rowNode?.data.id}"]`
            )?.[1];
            const rowInDocumentStatusIcon = rowInDocument
              ?.querySelector('[data-e2e="cg-icon"]')
              ?.querySelector('svg')
              ?.getAttribute('icon-name');
            if (
              !rowInDocument ||
              (rowInDocument && rowInDocumentStatusIcon !== CLOCK_ICON_LABEL)
            ) {
              clearInterval(increaseTimeInterval);
            } else {
              getOrchestrationDuration(orchestration, rowNode);
            }
          }, 10000);
        })();
      }
    });
  };

  const getOrchestrationDuration = (
    orchestration: IOrchestrationRow,
    rowNode?: RowNode
  ) => {
    const returnedTime =
      DateUtils.getFormattedDiff(
        orchestration.originalStartTime ?? orchestration.startTime,
        new Date().toISOString(),
        'd-h-m'
      ) || '<1m';
    rowNode?.setData({ ...rowNode.data, duration: returnedTime });
  };

  const getRowId = useMemo(() => {
    return (params: any) => {
      return params.data.id;
    };
  }, []);

  return (
    <div data-testid="Dashboard" className={style['dashboard']}>
      <div className={style['dashboard-div-status']}>
        <CsbErrorBoundary>
          <StatusContainer
            items={dashboardStatus.statuses}
            selectedStatus={dashboardStatus.selectedStatus}
          />
        </CsbErrorBoundary>
      </div>

      <div className={style['dashboard-div-actions']}>
        <CsbErrorBoundary>
          <TableActionsContainer>
            {{
              left: <TableActionsLeft />,
              right: <TableActionsRight />,
            }}
          </TableActionsContainer>
        </CsbErrorBoundary>
      </div>
      <div className={style['dashboard-div-table']}>
        <CsbErrorBoundary>
          <TableContainer
            items={orchestrationsState}
            columns={TABLE_COLUMNS}
            additionalData={{ getRowId }}
            onGridReadyAdditionalMethods={[
              { id: 'updateDurationColumn', method: updateDurationColumn },
            ]}
            isServerSideFiltering={true}
            page={PAGE_OPTIONS.orchestrations}
          />
        </CsbErrorBoundary>
      </div>
      <div className={style['dashboard-div-paginator']}>
        <CsbErrorBoundary>
          <PaginatorContainer />
        </CsbErrorBoundary>
      </div>
    </div>
  );
};

export default Dashboard;

import { ORCHESTRATION_STATUSES, STEP_STATUSES } from 'utils/common-constants';
import {
  IUpdateJobStatus,
  IJobStep,
} from 'interfaces/dashboard/dashboard-slice.interface';
import { IOrchestrationRow } from 'interfaces/dashboard/orchestration-row.interface';
import {
  calculateJobDurationIfApplies,
  combineStatus,
  getNameFromEmail,
  updateSteps,
} from 'utils/commonFunctions/CommonFunctions';
import { DateUtils } from 'utils/dateUtils/DateUtils';

export const mapResponseJobsToTableData = (jobs: any[]): IOrchestrationRow[] =>
  jobs.map((job) => mapJobToTableData(job));

export const mapJobToTableData = (job: any): IOrchestrationRow => {
  return {
    id: job.jobId,
    name: job.workflow?.name || '',
    submitTime: DateUtils.formatDateFromUtcString(job.submitTime),
    originalStartTime: job.startTime ?? job.submitTime,
    originalEndTime: job.endTime,
    startTime: job.startTime
      ? DateUtils.formatDateFromUtcString(job.startTime)
      : '',
    endTime:
      job.status !== ORCHESTRATION_STATUSES.PENDING
        ? DateUtils.formatDateFromUtcString(job.endTime)
        : '',
    duration: calculateJobDurationIfApplies(job),
    originalStatus: job.status,
    status: job.status
      ? combineStatus(job.status)
      : ORCHESTRATION_STATUSES.PENDING,
    originalUser: job.user,
    user: { name: getNameFromEmail(job.user?.email), id: job.user?.id },
    supportResubmit: supportResubmit(job),
    step: calculateStep(job),
    steps: job.steps,
    workflowId: job.workflow?.workflowId,
  };
};

export const updateJobStatusInTable = (
  job: IOrchestrationRow,
  updatedData: IUpdateJobStatus
): IOrchestrationRow => {
  const partialUpdatedJob = {
    ...job,
    status: combineStatus(updatedData.status),
    startTime: updatedData.startTime
      ? DateUtils.formatDateFromUtcString(updatedData.startTime)
      : job.startTime,
    endTime: updatedData.endTime
      ? DateUtils.formatDateFromUtcString(updatedData.endTime)
      : job.endTime,
    supportResubmit: supportResubmit(job),
  };
  return {
    ...partialUpdatedJob,
    duration: calculateJobDurationIfApplies(partialUpdatedJob),
    step: calculateStep(partialUpdatedJob),
  };
};

export const updateJobStepsInTable = (
  job: IOrchestrationRow,
  updatedData: IJobStep
) => {
  const updatedJob = updateSteps(job, updatedData);
  return {
    ...updatedJob,
    step: calculateStep(updatedJob),
  };
};

const supportResubmit = (job: any | IOrchestrationRow): boolean => {
  const statuses = [
    ORCHESTRATION_STATUSES.FAILED,
    ORCHESTRATION_STATUSES.FINISHED_ERROR,
  ];

  return (
    statuses.includes(job.status) &&
    ((job.workflow?.supportResubmit && job.masterJobId != null) ||
      job.supportResubmit === true)
  );
};

const calculateStep = (job: any): string => {
  const result: number[] = [];
  if (job.status === ORCHESTRATION_STATUSES.PENDING) {
    return '';
  } else if (job.status === ORCHESTRATION_STATUSES.STOPPING) {
    return 'Stopping';
  } else if (job.status === ORCHESTRATION_STATUSES.STOPPED) {
    return 'Stopped';
  } else if (job.status === ORCHESTRATION_STATUSES.FINISHED) {
    return 'Finished';
  } else if (job.status === ORCHESTRATION_STATUSES.FINISHED_ERROR) {
    return 'Finished with errors';
  } else if (job.status === ORCHESTRATION_STATUSES.FAILED) {
    return 'Failed';
  } else {
    return calculateStepNumberIfInProgress(job, result);
  }
};

const calculateStepNumberIfInProgress = (
  job: any,
  result: number[]
): string => {
  const stepsCount = job.steps?.length || 0;
  for (const [index, step] of (job.steps || []).entries()) {
    const realIndex = index + 1;
    if (index === 0 && step.status === STEP_STATUSES.NOT_STARTED) {
      return `Step ${realIndex} of ${stepsCount}`;
    }
    if (
      step.status === STEP_STATUSES.IN_PROGRESS ||
      step.status === STEP_STATUSES.NOT_STARTED
    ) {
      result.push(realIndex);
    }
  }
  return `${
    result.length > 1 ? `Steps ${result.join(', ')}` : `Step ${result}`
  } of ${stepsCount}`;
};

import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { IOrchestrationRow } from '../../interfaces/dashboard/orchestration-row.interface';
import style from './CellOptions.module.scss';
import { DropdownMoreButton } from '@wk/components-react16/dist/dropdown-more-button/dropdown-more-button';
import { Dropdown } from '@wk/components-react16';

interface ICellOptions {
  data?: IOrchestrationRow;
}

const CellOptions = ({ data }: ICellOptions) => {
  return (
    <CsbErrorBoundary>
      <div className={style['cell-options']} data-testid="CellOptions">
        <div className={style['dropdown']} data-testid="Dropdown">
          <DropdownMoreButton
            mode="blue"
            buttonId="options"
          ></DropdownMoreButton>
        </div>
      </div>
    </CsbErrorBoundary>
  );
};

export default CellOptions;

import { FC } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { IOrchestrationStatus } from '../../interfaces/dashboard/orchestration-status.interface';
import {
  composeFilterInfo,
  resetPaginatorInfo,
  setSelectedStatus,
} from '../../app/dashboard/DashboardSlice';
import StatusButton from '../StatusButton/StatusButton';
import style from './StatusContainer.module.scss';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';

interface IStatusContainerProps {
  items: IOrchestrationStatus[];
  selectedStatus: IOrchestrationStatus;
}

const StatusContainer: FC<IStatusContainerProps> = (props): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleStatusChange = (status: IOrchestrationStatus): void => {
    dispatch(setSelectedStatus(status));
    dispatch(resetPaginatorInfo());
    dispatch(composeFilterInfo());
  };

  return (
    <>
      <CsbErrorBoundary>
        <div
          className={style['status-container']}
          data-testid="StatusContainer"
        >
          <div className={style['status-container__header']}>
            <div className={style['status-container__header-title']}>
              <p>Orchestration Status</p>
            </div>
            <div className={style['status-container__header-item']}>
              {props.items.map(
                (status: IOrchestrationStatus): JSX.Element => (
                  <div
                    data-testid={status.id + '-button'}
                    key={status.id}
                    style={{
                      minWidth: '250px',
                      marginRight: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    <StatusButton
                      id={status.id}
                      filterId={status.filterId}
                      title={status.title}
                      isActive={props.selectedStatus.id === status.id}
                      description={status.description}
                      count={status.count || 0}
                      iconClass={status.iconClass}
                      iconColor={status.iconColor}
                      iconSize={status.iconSize}
                      onStatusChange={() => handleStatusChange(status)}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </CsbErrorBoundary>
    </>
  );
};

export default StatusContainer;

import {
  IFilterPlaceholderFunctionParams,
  ValueGetterParams,
} from 'ag-grid-enterprise';
import { ExtendedColDef } from 'interfaces/table.interface';
import {
  MAP_STATUS_ICON,
  ORCHESTRATION_FILTERS,
  ORCHESTRATION_STATUSES,
  TABLE_FIELDS,
} from '../../utils/common-constants';
import { IOrchestrationStatus } from '../../interfaces/dashboard/orchestration-status.interface';
import {
  defaultFilterParams,
  timeFilterOptions,
} from 'components/TableContainer/utils/filterUtils';

export const ORCHESTRATION_FILTER_ITEMS: IOrchestrationStatus[] = [
  {
    id: 'total',
    filterId: ORCHESTRATION_FILTERS.ANY,
    title: 'All',
    description: 'Orchestrations',
    iconClass: '',
    count: 0,
  },
  {
    id: 'pending',
    filterId: ORCHESTRATION_FILTERS.PENDING,
    title: 'Pending',
    description: 'Not started yet',
    iconClass: MAP_STATUS_ICON[ORCHESTRATION_STATUSES.PENDING].name,
    iconColor: MAP_STATUS_ICON[ORCHESTRATION_STATUSES.PENDING].color,
    iconSize: 24,
    count: 0,
  },
  {
    id: 'inProgress',
    filterId: ORCHESTRATION_FILTERS.IN_PROGRESS,
    title: 'Progress',
    description: 'Running',
    iconClass: MAP_STATUS_ICON[ORCHESTRATION_STATUSES.IN_PROGRESS].name,
    iconColor: MAP_STATUS_ICON[ORCHESTRATION_STATUSES.IN_PROGRESS].color,
    iconSize: 20,
    count: 0,
  },
  {
    id: 'failed',
    filterId: ORCHESTRATION_FILTERS.FAILED,
    title: 'Failed',
    description: 'With errors',
    iconClass: MAP_STATUS_ICON[ORCHESTRATION_STATUSES.FAILED].name,
    iconColor: MAP_STATUS_ICON[ORCHESTRATION_STATUSES.FAILED].color,
    iconSize: 20,
    count: 0,
  },
  {
    id: 'stopped',
    filterId: ORCHESTRATION_FILTERS.STOPPED,
    title: 'Stopped',
    description: 'Stopped by',
    iconClass: MAP_STATUS_ICON[ORCHESTRATION_STATUSES.STOPPED].name,
    iconColor: MAP_STATUS_ICON[ORCHESTRATION_STATUSES.STOPPED].color,
    iconSize: 20,
    count: 0,
  },
  {
    id: 'finished',
    filterId: ORCHESTRATION_FILTERS.FINISHED,
    title: 'Finished',
    description: 'Succeeded',
    iconClass: MAP_STATUS_ICON[ORCHESTRATION_STATUSES.FINISHED].name,
    iconColor: MAP_STATUS_ICON[ORCHESTRATION_STATUSES.FINISHED].color,
    iconSize: 20,
    count: 0,
  },
];

const DEFAULT_FILTER_MENU_ICON = '<i class="ag-icon ag-icon-menu"/>';

export const TABLE_COLUMNS: ExtendedColDef[] = [
  {
    headerName: 'ID',
    field: TABLE_FIELDS.id,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    suppressSizeToFit: true,
    menuTabs: ['filterMenuTab'],
    filter: 'agNumberColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      ...defaultFilterParams,
    },
    icons: {
      menu: DEFAULT_FILTER_MENU_ICON,
    },
    minWidth: 100,
    cellRenderer: 'cellLink',
    comparator: (a, b) => b - a,
  },
  {
    headerName: 'Orchestration',
    field: TABLE_FIELDS.name,
    menuTabs: ['filterMenuTab'],
    filter: 'agSetColumnFilter',
    filterParams: {
      defaultToNothingSelected: true,
      ...defaultFilterParams,
    },
    icons: {
      menu: DEFAULT_FILTER_MENU_ICON,
    },
    suppressSizeToFit: true,
    minWidth: 200,
    comparator: () => 0,
  },
  {
    headerName: 'Start Time',
    field: TABLE_FIELDS.startTime,
    filter: 'agDateColumnFilter',
    menuTabs: ['filterMenuTab'],
    filterParams: {
      filterOptions: timeFilterOptions,
      defaultOption: 'greaterThan',
      ...defaultFilterParams,
    },
    suppressSizeToFit: true,
    minWidth: 160,
  },
  {
    headerName: 'End Time',
    field: TABLE_FIELDS.endTime,
    filter: 'agDateColumnFilter',
    menuTabs: ['filterMenuTab'],
    filterParams: {
      filterOptions: timeFilterOptions,
      defaultOption: 'lessThan',
      ...defaultFilterParams,
    },
    suppressSizeToFit: true,
    minWidth: 160,
  },
  {
    headerName: 'Duration',
    field: TABLE_FIELDS.duration,
    menuTabs: ['filterMenuTab'],
    filter: 'agNumberColumnFilter',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'greaterThan',
          displayName: 'Greater Than',
          predicate: () => true,
        },
        {
          displayKey: 'lessThan',
          displayName: 'Less Than',
          predicate: () => true,
        },
        {
          displayKey: 'inRange',
          displayName: 'In Range',
          predicate: () => true,
          numberOfInputs: 2,
        },
      ],
      filterPlaceholder: (params: IFilterPlaceholderFunctionParams) => {
        if (params.placeholder === 'To') {
          return 'Enter to # minutes';
        } else {
          return 'Enter from # minutes';
        }
      },
      ...defaultFilterParams,
    },
    suppressSizeToFit: true,
    minWidth: 180,
    comparator: () => 0,
  },
  {
    headerName: 'Status',
    field: TABLE_FIELDS.status,
    cellRenderer: 'cellStatus',
    cellClass: 'csb-visible-cell',
    suppressSizeToFit: true,
    minWidth: 100,
    menuTabs: [],
  },
  {
    headerName: 'Job Started By',
    field: TABLE_FIELDS.user,
    minWidth: 160,
    filter: 'agSetColumnFilter',
    menuTabs: ['filterMenuTab'],
    filterParams: {
      defaultToNothingSelected: true,
      ...defaultFilterParams,
    },
    suppressSizeToFit: false,
    icons: {
      menu: DEFAULT_FILTER_MENU_ICON,
    },
    comparator: () => 0,
    filterValueGetter: (params: ValueGetterParams<any>) =>
      params.data.user.name,
  },
  {
    headerName: 'Step',
    field: TABLE_FIELDS.step,
    minWidth: 160,
    menuTabs: [],
    suppressSizeToFit: false,
  },
];

import {
  ButtonField,
  ButtonGroup,
  ButtonGroupItem,
  DropdownMenuItem,
  Iconsvg,
  Tooltip,
  TooltipBody,
} from '@wk/components-react16';
import { usePostDownloadLogMutation } from 'api/orchestrationApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { openModal } from 'app/modal/ModalSlice';
import {
  ORCHESTRATION_STATUSES,
  USER_PERMISSIONS,
} from 'utils/common-constants';
import { IOrchestrationRow } from 'interfaces/dashboard/orchestration-row.interface';
import { downloadLog } from 'utils/commonFunctions/CommonFunctions';
import { useGetEnviroment } from 'utils/hooks/useGetEnviroment';
import { selectUserPermissions } from 'app/auth/AuthSlice';
import { useState } from 'react';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { Useuid } from 'utils/hooks/useUid';

const OrchestrationHeader = ({
  selectedOrchestration,
}: IOrchestrationRow | any) => {
  const [downloadHook] = usePostDownloadLogMutation();
  const enviroment = useGetEnviroment();
  const dispatch = useAppDispatch();
  const userPermissions = useAppSelector((state) =>
    selectUserPermissions(state)
  );
  const [isErrorLogOpen, setIsErrorLogOpen] = useState<boolean>(false);
  const checkIfDisabled = (status: ORCHESTRATION_STATUSES): boolean => {
    if (
      status === ORCHESTRATION_STATUSES.FAILED ||
      status === ORCHESTRATION_STATUSES.FINISHED ||
      status === ORCHESTRATION_STATUSES.FINISHED_ERROR
    ) {
      return false;
    }
    return true;
  };

  const openErrorLogModal = () => {
    dispatch(
      openModal({
        id: '1',
        componentName: 'modals/errorLogModal',
        title: `Orchestration Instance Error Log Id: ${selectedOrchestration.id}`,
        footerName: 'cancel-download',
        display: true,
        componentProps: { data: selectedOrchestration },
        width: '1500px',
      })
    );
  };

  const openServiceNowModal = () => {
    dispatch(
      openModal({
        id: '1',
        componentName: 'modals/serviceNowModal',
        title: `Create ServiceNow Ticket`,
        footerName: 'cancel-submit',
        display: true,
        componentProps: { data: selectedOrchestration },
        width: '600px',
      })
    );
  };

  return (
    <CsbErrorBoundary>
      <ButtonGroup alignment={'left'} dropdownToggleSize="small16px">
        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'text'} size={'small'} iconPosition={'left'}>
            <div
              id="view-error-button"
              onMouseOver={() => setIsErrorLogOpen(true)}
              onMouseLeave={() => setIsErrorLogOpen(false)}
            >
              <button
                type="button"
                onClick={() => openErrorLogModal()}
                disabled={checkIfDisabled(selectedOrchestration?.status)}
                data-testid="ViewErrorLogBtn"
              >
                <Iconsvg name="visible"></Iconsvg>
                View error log
              </button>
            </div>
          </ButtonField>
          <Tooltip
            position="bottom"
            targetSelector="#view-error-button"
            id="view-tooltip"
            controlMode="controlled"
            isOpen={isErrorLogOpen}
          >
            <TooltipBody slot="tooltipBody">View error log</TooltipBody>
          </Tooltip>
        </ButtonGroupItem>

        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'text'} size={'small'} iconPosition={'left'}>
            <div id="download-log-button">
              <button
                type="button"
                onClick={() => downloadLog(selectedOrchestration, downloadHook)}
                disabled={checkIfDisabled(selectedOrchestration?.status)}
                data-testid="DownloadErrorLogBtn"
              >
                <Iconsvg name="download-line"></Iconsvg>
                Download error log
              </button>
            </div>
          </ButtonField>
          <Tooltip
            position="bottom"
            targetSelector="#download-log-button"
            id="download-tooltip"
          >
            <TooltipBody slot="tooltipBody">Download error log</TooltipBody>
          </Tooltip>
        </ButtonGroupItem>
        {enviroment != null &&
          userPermissions[USER_PERMISSIONS.SERVICE_NOW_CREATE] === 'Enable' && (
            <ButtonGroupItem slot="buttonGroupItem">
              <ButtonField mode={'text'} size={'small'} iconPosition={'left'}>
                <div id="service-ticket-button">
                  <button
                    type="button"
                    onClick={() => openServiceNowModal()}
                    disabled={checkIfDisabled(selectedOrchestration?.status)}
                    data-testid="CreateServiceBtn"
                  >
                    <Iconsvg name="plus"></Iconsvg>
                    Create ServiceNow ticket
                  </button>
                </div>
              </ButtonField>
              <Tooltip
                position="bottom"
                targetSelector="#service-ticket-button"
                id="service-tooltip"
              >
                <TooltipBody slot="tooltipBody">
                  Create ServiceNow ticket
                </TooltipBody>
              </Tooltip>
            </ButtonGroupItem>
          )}

        <DropdownMenuItem
          onItemClick={() => openErrorLogModal()}
          slot="buttonGroupDropdownItem"
        >
          <Iconsvg name="visible"></Iconsvg>
          View error log
        </DropdownMenuItem>
        <DropdownMenuItem
          onItemClick={() => downloadLog(selectedOrchestration, downloadHook)}
          slot="buttonGroupDropdownItem"
        >
          <Iconsvg name="download-line"></Iconsvg>
          Download error log
        </DropdownMenuItem>
        <DropdownMenuItem
          onItemClick={() => openServiceNowModal()}
          slot="buttonGroupDropdownItem"
        >
          <Iconsvg name="download-line"></Iconsvg>
          Create ServiceNow ticket
        </DropdownMenuItem>
      </ButtonGroup>
    </CsbErrorBoundary>
  );
};

export default OrchestrationHeader;

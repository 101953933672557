import { fetchEventSource } from '@microsoft/fetch-event-source';
import {
  addJob,
  updateCounters,
  updateJobStatus,
  updateStepStatus,
} from 'app/dashboard/DashboardSlice';
import { useAppDispatch } from 'app/hooks';
import {
  updateCurrentJobStatus,
  updateCurrentJobSteps,
} from 'app/propertiesPanel/PropertiesPanelSlice';
import { PAGE, SSE_EVENTS, SSE_STATUS } from 'utils/common-constants';
import { useEffect, useState } from 'react';

class RetriableError extends Error {}
class FatalError extends Error {}

export const useServerSideEvents = (eventTypes: SSE_EVENTS[], page: PAGE) => {
  let connectionStatus: any = SSE_STATUS.SUCCESS;
  const [connectionStatusState, setConnectionStatusState] =
    useState<any>(undefined);
  let abortController: AbortController;
  const dispatch = useAppDispatch();

  useEffect(() => {
    SSE();
    return () => {
      abortController && abortController.abort();
    };
  }, []);

  async function SSE() {
    abortController = new AbortController();
    await fetchEventSource(`${window.location.origin.toString()}/csb/sse`, {
      method: 'GET',
      headers: {
        Accept: 'text/event-stream',
      },
      signal: abortController.signal,
      openWhenHidden: true,
      async onopen(res) {
        if (res.ok && res.status === 200) {
          if (connectionStatus === SSE_STATUS.ERROR) {
            // set timeout to reconnect in 15 mins, to switch csb instance
            connectionStatus = SSE_STATUS.RESTORED;
            setConnectionStatusState(SSE_STATUS.RESTORED);
            console.log('SSE Connection restored');
            setTimeout(() => {
              abortController.abort();
            }, 900000);
          }
          console.log('SSE Connection made');
        } else if (
          res.status >= 400 &&
          res.status < 500 &&
          res.status !== 429
        ) {
          console.log('Client side error');
          console.log(res);
          throw new FatalError();
        } else {
          throw new RetriableError();
        }
      },
      onmessage(event) {
        eventTypes.forEach((type) => {
          if (type !== event.event || event.data == null) {
            return;
          }
          const parsedData = JSON.parse(event.data);
          switch (type) {
            case SSE_EVENTS.JOB_CREATED:
              dispatch(addJob({ job: parsedData }));
              break;
            case SSE_EVENTS.JOB_COUNTERS_UPDATE:
              dispatch(updateCounters(parsedData));
              break;
            case SSE_EVENTS.JOB_STATUS_UPDATE:
              page === PAGE.ORCHESTRATION_DETAILS
                ? dispatch(updateCurrentJobStatus(parsedData))
                : dispatch(updateJobStatus(parsedData));
              break;
            case SSE_EVENTS.JOB_STEP_UPDATE:
              page === PAGE.ORCHESTRATION_DETAILS
                ? dispatch(updateCurrentJobSteps(parsedData))
                : dispatch(updateStepStatus(parsedData));
              break;
            case SSE_EVENTS.ORCHESTRATION_CREATED:
              break;
            case SSE_EVENTS.ORCHESTRATION_DELETED:
              break;
            case SSE_EVENTS.ORCHESTRATION_UPDATED:
              break;
            default:
              break;
          }
        });
      },
      onclose() {
        // try to reconnect
        connectionStatus = SSE_STATUS.ERROR;
        setConnectionStatusState(SSE_STATUS.ERROR);
        console.log('Connection closed by the server');
        throw new RetriableError();
      },
      onerror(err) {
        // try to reconnect
        connectionStatus = SSE_STATUS.ERROR;
        setConnectionStatusState(SSE_STATUS.ERROR);
        console.log('There was an error from server');
        console.log(err);
      },
    });
  }

  return { connectionStatusState };
};

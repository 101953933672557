import { ButtonField, Tooltip, TooltipBody } from '@wk/components-react16';
import {
  usePostJobsMutation,
  usePostResubmitMutation,
  usePostStopMutation,
} from 'api/orchestrationApi';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { ORCHESTRATION_STATUSES } from 'utils/common-constants';
import { IUser } from 'interfaces/user.interface';
import { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import style from './TableActionsRight.module.scss';
import { selectFilterStatus } from 'app/dashboard/DashboardSlice';
import { consoleErrorMessage } from 'utils/commonFunctions/CommonFunctions';

export const hasPermission = (
  user: IUser | null,
  requiredPermission: string
): boolean => {
  if (!user) {
    return false;
  }
  const permissions = user.userRole?.permissions || [];
  const found = Object.keys(permissions).find(
    (permission) => permission === requiredPermission
  );

  return found
    ? permissions[found] === 'Enable' || permissions[found] === 'Allow'
    : false;
};

const TableActionsRight: FC<any> = (props) => {
  const selectedOrchestrationsState = useAppSelector(
    (state) => state.dashboardContainer
  ).selectedOrchestrations;
  const authUser = useAppSelector((state) => state.auth.user);
  const [enableResubmit, setEnableResubmit] = useState<boolean>(false);
  const [enableStop, setEnableStop] = useState<boolean>(false);
  const [tooltipResubmit, setTooltipResubmit] = useState<string>('');
  const [tooltipStop, setTooltipStop] = useState<string>('');
  const [reSubmitOrchestration] = usePostResubmitMutation();
  const [stopOrchestration] = usePostStopMutation();
  const [loadDashboardInfo] = usePostJobsMutation();
  const filterInfo = useAppSelector((state) => selectFilterStatus(state));

  useEffect(() => {
    const length = selectedOrchestrationsState.length;
    let allResubmit = true;
    const allStop = hasPermission(authUser, 'ORCHESTRATION_STOP');

    selectedOrchestrationsState.forEach((orchestration) => {
      allResubmit = allResubmit && (orchestration.supportResubmit || false);
    });

    if (length === 0) {
      setEnableResubmit(false);
      setTooltipResubmit('Resubmit orchestrations');
      setEnableStop(false);
      setTooltipStop('Stop active orchestrations');
      return;
    }

    if (allResubmit) {
      setEnableResubmit(true);
      setTooltipResubmit('Resubmit orchestrations');
    } else {
      setEnableResubmit(false);
      if (length > 1) {
        setTooltipResubmit(
          'Resubmit orchestration may be valid for one or more selected orchestrations but not for all selected orchestrations'
        );
      } else {
        setTooltipResubmit('Resubmit orchestration');
      }
    }

    if (allStop) {
      setEnableStop(true);
    }
  }, [authUser, selectedOrchestrationsState]);

  const onResubmitClick = (): void => {
    const calls = selectedOrchestrationsState.map((orchestration) =>
      reSubmitOrchestration(orchestration.id)
    );
    Promise.all(calls).then(() => {});
  };

  const onStopClick = (): void => {
    const calls = selectedOrchestrationsState
      .filter((orchestration) => {
        return (
          orchestration.status === ORCHESTRATION_STATUSES.IN_PROGRESS ||
          orchestration.status === ORCHESTRATION_STATUSES.PENDING
        );
      })
      .map((orchestration) => stopOrchestration(orchestration.id));
    Promise.all(calls)
      .then(() => loadDashboardInfo(filterInfo || {}))
      .catch((error: any) => consoleErrorMessage(error))
      .finally(() => {});
  };

  return (
    <CsbErrorBoundary>
      <>
        <div
          className={style['table-actions-right']}
          data-testid="TableActionsRight"
        >
          <ButtonField size={'small'}>
            <div id="stop-orchestrations">
              <button
                type="button"
                className={style['table-actions-right__stop']}
                disabled={!enableStop}
                onClick={onStopClick}
                data-testid="StopButton"
              >
                Stop orchestration
              </button>
            </div>
          </ButtonField>
          <Tooltip position="top" targetSelector="#stop-orchestrations">
            <TooltipBody slot="tooltipBody">{tooltipStop}</TooltipBody>
          </Tooltip>
          <ButtonField mode={'ghost'} size={'small'}>
            <div id="resubmit-orchestrations">
              <button
                type="button"
                className={style['table-actions-right__resubmit']}
                disabled={!enableResubmit}
                data-testid="ResubmitButton"
                onClick={onResubmitClick}
              >
                Resubmit{' '}
              </button>
            </div>
          </ButtonField>
          {tooltipResubmit && (
            <Tooltip
              position="top"
              targetSelector="#resubmit-orchestrations"
              mode="description"
              size="auto"
            >
              <TooltipBody slot="tooltipBody">
                <div style={{ maxWidth: '180px' }}>{tooltipResubmit}</div>
              </TooltipBody>
            </Tooltip>
          )}
        </div>
      </>
    </CsbErrorBoundary>
  );
};

export default TableActionsRight;

import { Iconsvg, Tooltip, TooltipBody } from '@wk/components-react16';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { FC } from 'react';
import { IOrchestrationStatus } from '../../interfaces/dashboard/orchestration-status.interface';
import style from './StatusButton.module.scss';

const StatusButton: FC<IOrchestrationStatus> = (props): JSX.Element => {
  const {
    title,
    description,
    count,
    isActive,
    iconClass,
    iconColor,
    iconSize,
    onStatusChange,
  } = props;

  const onItemClick = (): void => {
    if (onStatusChange) {
      onStatusChange(!isActive);
    }
  };

  return (
    <CsbErrorBoundary>
      <div
        id={`${title}${count}`}
        className={
          isActive
            ? `${style['is-active']} ${style['status-button']}`
            : style['status-button']
        }
        onClick={onItemClick}
        data-testid="status-button"
      >
        <Tooltip
          id="tooltip-top"
          position="top"
          targetSelector={`#${title}${count}`}
        >
          <TooltipBody slot="tooltipBody">{`${title} - ${count}`}</TooltipBody>
        </Tooltip>
        <p className={style['count']}>{count || 0}</p>
        <div className={style['button-content']}>
          {iconClass ? (
            <p className={style['icon']}>
              <Iconsvg
                name={iconClass}
                title={`${title} - ${iconClass}`}
                color={iconColor || 'black'}
                size={iconSize || 20}
              ></Iconsvg>
            </p>
          ) : null}
          <div className={style['status-texts']}>
            <div className={style['title']}>{title}</div>
            <div className={style['description']}>{description}</div>
          </div>
        </div>
      </div>
    </CsbErrorBoundary>
  );
};

export default StatusButton;

export interface ColumnViewIconProps {
  width?: number;
  height?: number;
  stroke?: string;
}
export const ColumnViewIcon = ({
  width,
  height,
  stroke,
}: ColumnViewIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? 16}
    height={height ?? 14}
    fill="none"
    data-testid="ColumnViewIcon"
  >
    <path
      fill="#fff"
      stroke={stroke ?? '#353535'}
      d="M5.5.5h5v13h-5zM10.5.5h5v13h-5zM.5.5h5v13h-5z"
    />
  </svg>
);

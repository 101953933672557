import { useState } from 'react';

export const useTooltipControlled = (values: string[]) => {
  const [tooltipsState, setTooltip] = useState({
    ...values.map((val: string) => ({ val: false })),
  });
  const handleTooltip = (value: string, state: boolean) =>
    setTooltip((prev: any) => ({ ...prev, [value]: state }));
  const getValue = (value: string) =>
    (tooltipsState[value as any] as unknown as boolean) || undefined;
  return { tooltipsState, handleTooltip, getValue };
};

import { Iconsvg, Tooltip, TooltipBody } from '@wk/components-react16';
import { InputField } from '@wk/components-react16/dist/input-field/input-field';
import { TextareaField } from '@wk/components-react16/dist/textarea-field/textarea-field';
import { useEffect, useState, useRef } from 'react';
import {
  isPropertyToBeMasked,
  maskValue,
} from 'utils/commonFunctions/CommonFunctions';
import style from './TextFieldOrTextAreaSwitcher.module.scss';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { SouceFromBuIcon } from 'components/CustomIcons/SourceFromBuIcon/SourceFromBuIcon';

export interface TextFieldOrTextAreaSwitcherProps {
  value: string | undefined;
  label: string;
  id: string;
  testid?: string;
  type?: string;
  isSourcedFromBu?: boolean;
}

const TextFieldOrTextAreaSwitcher = ({
  value,
  label,
  id,
  testid,
  type,
  isSourcedFromBu,
}: TextFieldOrTextAreaSwitcherProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [switchToTextArea, setSwitchToTextArea] = useState<boolean>(false);
  const setInputValue =
    isPropertyToBeMasked(label) && value ? maskValue(value) : value;

  useEffect(() => {
    setTimeout(() => {
      if (
        inputRef?.current &&
        value &&
        inputRef.current.clientWidth + 1 < inputRef.current.scrollWidth
      ) {
        setSwitchToTextArea(true);
      }
    }, 0);
  }, [inputRef?.current, value]);

  return (
    <CsbErrorBoundary>
      <div className={style['text-field-or-text-area-switcher']}>
        {switchToTextArea ? (
          <TextareaField label={label} labelFor={id} size="small" key={id}>
            <SouceFromBuIcon isSourcedFromBu={isSourcedFromBu} id={id} />
            <textarea
              id={id}
              name={id}
              readOnly
              value={setInputValue}
              data-testid={testid ?? 'TextArea'}
            />
          </TextareaField>
        ) : (
          <InputField label={label} labelFor={id} size="small" key={id}>
            <SouceFromBuIcon
              isSourcedFromBu={isSourcedFromBu}
              id={id}
              type="top"
            />
            <input
              type={type ?? 'text'}
              id={id}
              name={id}
              readOnly
              value={setInputValue}
              data-testid={testid ?? 'Input'}
              ref={inputRef}
            />
          </InputField>
        )}
      </div>
    </CsbErrorBoundary>
  );
};

export default TextFieldOrTextAreaSwitcher;

import {
  IStepInformation,
  IStepProperties,
} from 'interfaces/orchestrationDiagram/orchestration-step';
import { IOrchestrationSidePanelSteps } from 'interfaces/orchestrationDiagram/orchestration-diagram';
import { DateUtils } from 'utils/dateUtils/DateUtils';

export const mapOrchestrationStep = (
  step: any,
  stepProperties: IStepInformation
): IOrchestrationSidePanelSteps => {
  return {
    id: step?.workflowStepId.toString(),
    jobId: stepProperties?.jobId?.toString(),
    jobStepId: stepProperties?.jobStepId?.toString(),
    index: step?.stepIndex,
    name: step?.stepTitle,
    stepCondition:
      step?.stepCondition != null && step?.stepCondition !== ''
        ? step?.stepCondition
        : null,
    moduleName: step?.module?.name,
    moduleDescription: step?.module?.description,
    moduleVersion: step?.module?.version,
    information: {
      startTime:
        DateUtils.formatDateFromUtcString(
          stepProperties?.startTime?.toString()
        ) ?? '-',
      endTime:
        DateUtils.formatDateFromUtcString(
          stepProperties?.endTime?.toString()
        ) ?? '-',
      duration:
        DateUtils.getFormattedDiff(
          stepProperties?.startTime?.toLocaleString(),
          stepProperties?.endTime?.toLocaleString() || new Date().toISOString()
        ) || '1s',
      executionNumber: stepProperties?.executionNumber,
      status: stepProperties?.status,
      retryNumber: step?.retryNumber > 0 ? step?.retryNumber : null,
      retryDelay: step?.retryDelay > 0 ? step?.retryDelay : null,
      retryDelayMultiplier:
        step?.retryDelayMultiplier > 0 ? step?.retryDelayMultiplier : null,
    },
    stepProperties: tagPropertiesCommingFromBu(
      stepProperties?.jobStepParams,
      step?.paramGroups?.[0]?.parameters
    ),
    defaultStepProperties: getDefaultStepProperties(
      step?.paramGroups?.[0]?.parameters
    ),
    isOpen: false,
  };
};

const getDefaultStepProperties = (paramGroups: any[]) => {
  if (!paramGroups) {
    return [];
  }
  return paramGroups
    ?.map((paramGroup) => {
      if (paramGroup.value || (paramGroup.isDynamic && paramGroup.dynamic)) {
        return {
          jobStepParamId: paramGroup.parameter?.id,
          paramName: paramGroup.parameter?.name,
          paramValue: paramGroup.value,
        } as IStepProperties;
      } else {
        return null;
      }
    })
    .filter((property) => property != null);
};

const tagPropertiesCommingFromBu = (
  properties: IStepProperties[],
  paramGroup: any[]
): IStepProperties[] => {
  return properties?.map((property: IStepProperties) => {
    const propertyInParamsGroup = paramGroup.find(
      (param: any) =>
        param.parameter?.name === property.paramName && param.value
    );
    const isBuSourceFromBu =
      propertyInParamsGroup?.configuredExternally ?? false;
    return { ...property, isBuSource: isBuSourceFromBu };
  });
};

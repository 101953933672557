import { Iconsvg } from '@wk/components-react16/dist/iconsvg/iconsvg';
import style from './TableOverlayComponent.module.scss';
import { PAGE_OPTIONS } from 'interfaces/table.interface';

export interface ITableOverlayComponent {
  page?: PAGE_OPTIONS;
}

const TableOverlayComponent = (props: ITableOverlayComponent) => {
  return (
    <div className={style['csb-table-overlay']} data-testid="TableOverlay">
      <span className={style['csb-table-overlay-text']}>
        {props.page === PAGE_OPTIONS.errorLogs
          ? 'Please wait while your orchestration log data is loaded'
          : props.page === PAGE_OPTIONS.orchestrations
          ? 'Please wait while your orchestrations are loading'
          : 'Please wait while your workflows are loading'}
      </span>
      <Iconsvg name="spinner-alt" isSpin isFill size={24}></Iconsvg>
    </div>
  );
};

export default TableOverlayComponent;

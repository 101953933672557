import {
  format,
  formatDistanceStrict,
  formatDuration,
  intervalToDuration,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export const getTimeZone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export class DateUtils {
  static getDate(date: Date): string {
    return date.toLocaleDateString();
  }

  static getTime(date: Date): string {
    return date.toLocaleTimeString();
  }

  static getDateTime(date: Date): string {
    return `${DateUtils.getDate(date)} ${DateUtils.getTime(date)}`;
  }

  static getFormattedDiff(
    start?: string,
    end?: string,
    range?: 'full' | 'd-h-m'
  ): string {
    if (!start || !end) {
      return 'NA';
    }

    let parsedStart = Date.parse(start);
    let parsedEnd = Date.parse(end);

    if (isNaN(parsedStart) || isNaN(parsedEnd)) {
      return 'NA';
    }

    if (parsedStart > parsedEnd) {
      [start, end] = [end, start]; // This swaps the dates
      [parsedStart, parsedEnd] = [parsedEnd, parsedStart]; // This swaps the dates
    }
    // even positions have plural form
    const formatRanges = {
      years: 'y',
      year: 'y',
      months: 'm',
      month: 'm',
      weeks: 'w',
      week: 'w',
      days: 'd',
      day: 'd',
      hours: 'h',
      hour: 'h',
      minutes: 'm',
      minute: 'm',
      seconds: 's',
      second: 's',
    };
    const timezone = getTimeZone();
    const onlyChars = /[a-z]+/gi;
    let duration;
    try {
      if (range === 'd-h-m') {
        const durationObject = intervalToDuration({
          start: parsedStart,
          end: parsedEnd,
        });
        const exactHours = formatDistanceStrict(
          zonedTimeToUtc(parsedStart, timezone),
          zonedTimeToUtc(parsedEnd, timezone),
          {
            unit: 'hour',
          }
        );
        if (durationObject.days && exactHours) {
          durationObject.days =
            Math.trunc(Number(exactHours.match(/([0-9]+)/g)?.[0]) / 24) +
            (durationObject.years ?? 0 * 365);
        }
        duration = formatDuration(durationObject, {
          format: ['days', 'hours', 'minutes'],
        });
      } else {
        //TODO: Change try catch for better validation
        duration = formatDuration(
          intervalToDuration({
            start: utcToZonedTime(new Date(start), timezone),
            end: utcToZonedTime(new Date(end), timezone),
          }),
          {
            format: Object.keys(formatRanges).filter((x, i) => i % 2 === 0),
          }
        );
      }
      const formattedDuration = DateUtils.replaceAll(
        duration.trim().replace(/\s/g, ''),
        formatRanges
      );

      return formattedDuration.replace(onlyChars, (matched) => `${matched} `);
    } catch {
      console.error(`Error with getFormattedDiff start:${start} - end:${end}`);
      return 'NA';
    }
  }

  static replaceAll(str: string, mapObj: Record<string, string>): string {
    const re = new RegExp(Object.keys(mapObj).join('|'), 'gi');

    return str.replace(re, (matched) => mapObj[matched.toLowerCase()]);
  }

  static formatDateFromUtcString(
    utcDate: string,
    applyTimeZone = false
  ): string {
    if (utcDate === null || utcDate === undefined || utcDate === '') {
      return '';
    }

    const timezone = applyTimeZone ? getTimeZone() : 'UTC';
    const date = utcToZonedTime(new Date(utcDate), timezone);

    return date.toLocaleString(navigator.language, { hour12: false });
  }

  static formatDateForDetail(utcDate: string): string {
    if (utcDate === null || utcDate === undefined || utcDate === '') {
      return '';
    }
    const date = utcToZonedTime(new Date(utcDate), 'UTC');

    return format(date, 'dd MMMM yyyy HH:mm');
  }
}

import { STEP_STATUSES } from 'utils/common-constants';

export const useCompleteStatusColor = (status: STEP_STATUSES) => {
  switch (status) {
    case STEP_STATUSES.NOT_STARTED:
      return 'black';
    case STEP_STATUSES.IN_PROGRESS:
      return 'black';
    case STEP_STATUSES.FINISHED:
      return '#85BC20';
    case STEP_STATUSES.FINISHED_ERROR:
      return '#85BC20';
    case STEP_STATUSES.FAILED:
      return 'red';
    case STEP_STATUSES.QUEUED:
      return 'black';
    default:
      return 'black';
  }
};

export const useTooltipStatusColor = (status: STEP_STATUSES) => {
  switch (status) {
    case STEP_STATUSES.NOT_STARTED:
      return 'black';
    case STEP_STATUSES.IN_PROGRESS:
      return 'black';
    case STEP_STATUSES.FAILED:
      return 'red';
    case STEP_STATUSES.FINISHED:
      return 'green';
    case STEP_STATUSES.FINISHED_ERROR:
      return 'green';
    case STEP_STATUSES.QUEUED:
      return 'black';
    default:
      return 'black';
  }
};

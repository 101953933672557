import {
  AccordionItem,
  AccordionItemBody,
  AccordionItemHeader,
} from '@wk/components-react16';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectOrchetsrationInstanceStep,
  switchOpenStatus,
  selectPropertiesPanel,
} from 'app/propertiesPanel/PropertiesPanelSlice';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import TextFieldOrTextAreaSwitcher from 'components/TextFieldOrTextAreaSwitcher/TextFieldOrTextAreaSwitcher';
import {
  IOrchestrationInstancePanel,
  IOrchestrationSidePanelSteps,
} from 'interfaces/orchestrationDiagram/orchestration-diagram';
import style from './OrchestrationInstanceAccordion.module.scss';

export const OrchestrationInstanceAccordion = () => {
  const instancePanelStep: IOrchestrationInstancePanel | undefined =
    useAppSelector((state) => selectOrchetsrationInstanceStep(state));
  const orchestrationSteps: IOrchestrationSidePanelSteps[] = useAppSelector(
    (state) => selectPropertiesPanel(state)
  );
  const dispatch = useAppDispatch();

  const formmatFailedStep = (): string => {
    const failedStepIndex = orchestrationSteps.findIndex(
      (step) => step.id === instancePanelStep?.firstFailedStepId
    );
    if (failedStepIndex !== -1) {
      return `Step ${failedStepIndex + 1} ${
        orchestrationSteps[failedStepIndex].name
      }`;
    } else {
      return '-';
    }
  };

  return (
    <CsbErrorBoundary>
      <div
        data-testid="OrchestrationInstanceAccordion"
        className={`${style['orchestration-instance-accordion']} ${
          instancePanelStep?.isOpen ? style['accordion-wk-open'] : ''
        }`}
      >
        <AccordionItem
          isOpen={instancePanelStep?.isOpen ?? true}
          onUserRequest={(event: boolean) =>
            dispatch(switchOpenStatus({ id: '', swtichInstance: true }))
          }
          isScrollbarEnabled={false}
        >
          <AccordionItemHeader slot="accordionItemHeader">
            <p className={style['accordion-header-title']}>
              {instancePanelStep?.name}
            </p>
          </AccordionItemHeader>
          <AccordionItemBody slot="accordionItemBody">
            <section className={style['accordion-body']}>
              <TextFieldOrTextAreaSwitcher
                label="Orchestration ID"
                id="orchestrationId"
                value={instancePanelStep?.id}
              />
              <TextFieldOrTextAreaSwitcher
                label="Orchestration name"
                id="orchestrationName"
                value={instancePanelStep?.name}
              />
              <TextFieldOrTextAreaSwitcher
                label="Failed module"
                id="failedModule"
                testid="OrchestrationInstanceAccordion-failedStep"
                value={formmatFailedStep()}
              />
              <TextFieldOrTextAreaSwitcher
                label="Duration"
                id="jobDuration"
                value={instancePanelStep?.duration ?? '-'}
              />
              <TextFieldOrTextAreaSwitcher
                label="Start time"
                id="jobStartTime"
                value={instancePanelStep?.startTime ?? '-'}
              />
              <TextFieldOrTextAreaSwitcher
                label="End time"
                id="jobEndTime"
                value={instancePanelStep?.endTime ?? '-'}
              />
              <span className={style['accordion-subtitle']}>
                Additional information
              </span>
              <TextFieldOrTextAreaSwitcher
                label="Submit time"
                id="jobSubmitTime"
                value={instancePanelStep?.submitTime ?? '-'}
              />
              <TextFieldOrTextAreaSwitcher
                label="Owner"
                id="jobOwner"
                value={instancePanelStep?.owner ?? '-'}
              />
              {instancePanelStep?.inputName && (
                <TextFieldOrTextAreaSwitcher
                  label="Input name"
                  id="jobInputName"
                  value={
                    instancePanelStep?.inputName.replace(/,/g, ',\n\n') ?? '-'
                  }
                />
              )}
              {instancePanelStep?.inputSize && (
                <TextFieldOrTextAreaSwitcher
                  label="Input size"
                  id="jobInputSize"
                  value={instancePanelStep?.inputSize ?? '-'}
                />
              )}
              {instancePanelStep?.timeoutAfternH && (
                <TextFieldOrTextAreaSwitcher
                  label="Timeout after nH"
                  id="timeoutAfternH"
                  value={instancePanelStep?.timeoutAfternH?.toString() ?? '-'}
                />
              )}
              {instancePanelStep?.supportsPrioritization && (
                <TextFieldOrTextAreaSwitcher
                  label="Orchestration priority"
                  id="orchestrationPriority"
                  value={
                    instancePanelStep?.orchestrationPriority?.toString() ?? '-'
                  }
                />
              )}
            </section>
          </AccordionItemBody>
        </AccordionItem>
      </div>
    </CsbErrorBoundary>
  );
};

import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './RunOrchestrationBanner.module.scss';
import { SearchField } from '@wk/components-react16/dist/search-field/search-field';
import { useEffect, useState } from 'react';
import { Iconsvg } from '@wk/components-react16/dist/iconsvg/iconsvg';
import { SplitViewIcon } from 'components/CustomIcons/SplitViewIcon';
import { ColumnViewIcon } from 'components/CustomIcons/ColumnViewIcon';
import {
  ToggleField,
  ToggleFieldGroup,
  Tooltip,
  TooltipBody,
  WkSwitch,
} from '@wk/components-react16';
import { useTooltipControlled } from 'utils/hooks/useTooltipControlled';
import { selectUserRole } from 'app/auth/AuthSlice';
import { useAppSelector } from 'app/hooks';
import { USERS_ROLES } from 'utils/common-constants';
import { RunOrchestrationButtons } from './RunOrchestrationButtons/RunOrchestrationButtons';

export const RunOrchestrationBanner = () => {
  const [switchStatus, setSwitchStatus] = useState(false);
  const [toggleGroupStatus, setToggleGroupStatus] = useState(0);
  const userRole = useAppSelector(selectUserRole);
  const [isDevOrAdmin, setIsDevOrAdmin] = useState(undefined);
  const { handleTooltip, getValue } = useTooltipControlled([
    'switch',
    'listView',
    'splitView',
    'columnView',
  ]);

  const handleSwitch = () => {
    setSwitchStatus((switchStatus) => !switchStatus);
  };
  const viewsData = [
    { id: 'listView', text: 'Display list view' },
    { id: 'splitView', text: 'Display split view' },
    { id: 'columnView', text: 'Display column view' },
  ];

  useEffect(() => {
    setIsDevOrAdmin(
      userRole.includes(USERS_ROLES.DEVELOPER) ||
        userRole.includes(USERS_ROLES.ADMIN)
    );
  }, [userRole]);

  return (
    <CsbErrorBoundary>
      <div
        className={style['banner-container']}
        data-testid="RunOrchestrationBanner"
      >
        <p className={style['title-row']}>Run Orchestration</p>
        <div className={style['toolbar-row']}>
          <div className={style['search-bar']}>
            <SearchField>
              <input
                type="search"
                id="search"
                name="search"
                placeholder={`Search for an orchestration`}
              />
            </SearchField>
          </div>
          <div
            className={style['toogle']}
            id="my-orchestrations-toggle"
            data-testid="OrchestrationToogle"
            onMouseEnter={() => handleTooltip('switch', true)}
            onMouseLeave={() => handleTooltip('switch', false)}
          >
            <WkSwitch
              name="activeWorkflowsSwtich"
              isChecked={switchStatus}
              label="View only Active"
              size="small"
              onChange={() => handleSwitch()}
            ></WkSwitch>
            <Tooltip
              position="bottom"
              targetSelector="#my-orchestrations-toggle"
              controlMode="controlled"
              isOpen={getValue('switch')}
            >
              <TooltipBody slot="tooltipBody">
                View your active orchestrations only
              </TooltipBody>
            </Tooltip>
          </div>
          <div className={style['view-status']}>
            <span>View</span>
            <ToggleFieldGroup srLabel={'Toggle button group example'}>
              <ToggleField>
                <div
                  onClick={() => setToggleGroupStatus(0)}
                  id="listView"
                  data-testid="ToggleList"
                  onMouseEnter={() => handleTooltip('listView', true)}
                  onMouseLeave={() => handleTooltip('listView', false)}
                >
                  <label htmlFor="toggle-list" aria-label="Toggle list">
                    <input
                      type="radio"
                      name="toggle-radio"
                      id="toggle-list"
                      checked={toggleGroupStatus === 0}
                    />
                    <Iconsvg name="list-bullet-dot"></Iconsvg>
                  </label>
                </div>
              </ToggleField>
              <ToggleField>
                <div
                  className={style['custom-icon']}
                  onClick={() => setToggleGroupStatus(1)}
                  data-testid="ToggleSplit"
                  id="splitView"
                  onMouseEnter={() => handleTooltip('splitView', true)}
                  onMouseLeave={() => handleTooltip('splitView', false)}
                >
                  <label htmlFor="toggle-grid" aria-label="Toggle grid">
                    <input type="radio" name="toggle-radio" id="toggle-grid" />
                    <Iconsvg>
                      <SplitViewIcon
                        stroke={toggleGroupStatus === 1 ? '#005B92' : undefined}
                      />
                    </Iconsvg>
                  </label>
                </div>
              </ToggleField>
              <ToggleField>
                <div
                  className={style['custom-icon']}
                  onClick={() => setToggleGroupStatus(2)}
                  data-testid="ToggleColumn"
                  id="columnView"
                  onMouseEnter={() => handleTooltip('columnView', true)}
                  onMouseLeave={() => handleTooltip('columnView', false)}
                >
                  <label htmlFor="toggle-column" aria-label="Toggle column">
                    <input
                      type="radio"
                      name="toggle-radio"
                      id="toggle-column"
                    />
                    <Iconsvg>
                      <ColumnViewIcon
                        stroke={toggleGroupStatus === 2 ? '#005B92' : undefined}
                      />
                    </Iconsvg>
                  </label>
                </div>
              </ToggleField>
            </ToggleFieldGroup>
            {viewsData.map((item: any) => (
              <Tooltip
                position="bottom"
                targetSelector={`#${item.id}`}
                controlMode="controlled"
                isOpen={getValue(item.id)}
              >
                <TooltipBody slot="tooltipBody">{item.text}</TooltipBody>
              </Tooltip>
            ))}
          </div>
          <RunOrchestrationButtons isDevOrAdmin={isDevOrAdmin} />
        </div>
      </div>
    </CsbErrorBoundary>
  );
};

import { useLazyGetWorkflowsQuery } from 'api/orchestrationApi';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import TableContainer from '../../components/TableContainer/TableContainer';
import style from './RunOrchestrations.module.scss';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';

import { RUN_ORCHESTRATIONS_TABLE_COLUMNS } from './RunOrchestrations.constants';
import {
  selectWorkflows,
  setSelectedWorkflow,
  startLoading,
} from 'app/runOrchestrations/RunOrchestrationsSlice';

import {
  IWorkflowProduct,
  IWorkflow,
} from 'interfaces/runOrchestration/workflow-row';
import { PAGE_OPTIONS } from 'interfaces/table.interface';
import { RunOrchestrationBanner } from 'components/RunOrchestrationBanner/RunOrchestrationBanner';
import { selectUserRole } from 'app/auth/AuthSlice';
import { USERS_ROLES } from 'utils/common-constants';

const RunOrchestrations = () => {
  const dispatch = useAppDispatch();
  const workflowsState: IWorkflow[] | IWorkflowProduct[] =
    useAppSelector(selectWorkflows);
  const userRole = useAppSelector(selectUserRole);
  const [loadWorkflows] = useLazyGetWorkflowsQuery();
  const [tableColumns, setTableColumns] = useState<any>(
    RUN_ORCHESTRATIONS_TABLE_COLUMNS
  );
  const getInfo = useCallback(async () => {
    await loadWorkflows(null);
  }, []);

  useEffect(() => {
    dispatch(startLoading());
    getInfo();
    if (
      !userRole.includes(USERS_ROLES.DEVELOPER) &&
      !userRole.includes(USERS_ROLES.ADMIN)
    ) {
      setTableColumns(RUN_ORCHESTRATIONS_TABLE_COLUMNS.slice(0, 5));
    }
    return () => {
      dispatch(setSelectedWorkflow(null));
    };
  }, [getInfo]);

  const getRowId = useMemo(() => {
    return (params: any) => {
      return params.data.id;
    };
  }, []);

  return (
    <div
      data-testid="RunOrchestrations"
      className={style['run-orchestrations']}
    >
      <div className={style['run-orchestrations-banner']}>
        <CsbErrorBoundary>
          <RunOrchestrationBanner />
        </CsbErrorBoundary>
      </div>

      <div className={style['run-orchestrations-div-table']}>
        <CsbErrorBoundary>
          <TableContainer
            items={workflowsState ?? []}
            columns={tableColumns}
            onGridReadyAdditionalMethods={[]}
            additionalData={{ getRowId }}
            page={PAGE_OPTIONS.runOrchestrations}
            additionalStyles={''}
          />
          <div className={style['run-orchestrations-bottom-border']}></div>
        </CsbErrorBoundary>
      </div>
    </div>
  );
};

export default RunOrchestrations;

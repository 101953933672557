export const MODAL_FORMS_LIST: { [key: string]: any } = {
  'modals/errorLogModal': () =>
    import('components/Modals/body/errorLogModal/ErrorLogModal'),
  'modals/serviceNowModal': () =>
    import('components/Modals/body/ServiceNowModal/ServiceNowModal'),
  'modals/configureAndRun': () =>
    import('components/Modals/body/ConfigureAndRunModal/ConfigureAndRunModal'),
};

export const MODAL_FOOTER_LIST: { [key: string]: any } = {
  'cancel-reset-apply': () =>
    import(
      'components/Modals/footers/CancelResetApplyFooter/CancelResetApplyFooter'
    ),
  'cancel-download': () =>
    import(
      'components/Modals/footers/CancelDownloadFooter/CancelDownloadFooter'
    ),
  'cancel-submit': () =>
    import('components/Modals/footers/CancelSubmitFooter/CancelSubmitFooter'),
  'cancel-next': () =>
    import('components/Modals/footers/CancelNextSubmit/CancelNextSubmitFooter'),
};

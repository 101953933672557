import { useAppDispatch } from 'app/hooks';
import { IToast } from 'interfaces/toasts';
import { Notification } from '@wk/components-react16';
import { removeToast } from 'app/toast/toastSlice';
import { capitalizeFirstLetter } from 'utils/commonFunctions/CommonFunctions';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';

export interface IConfigureAndRunToast {
  item: IToast;
}

export const ConfigureAndRunToast = ({ item }: IConfigureAndRunToast) => {
  const dispatch = useAppDispatch();
  return (
    <CsbErrorBoundary>
      <div data-testid={`ConfigureAndRunToast-${item.id}`}>
        <Notification
          controlMode="uncontrolled"
          isOpen={true}
          mode="toast"
          type={item.status}
          autoCloseTimeout={5000}
          hasCloseButton
          heading={capitalizeFirstLetter(item.status)}
          onCloseEnd={() => dispatch(removeToast(item.id))}
          key={item.id}
        >
          <span slot="notificationContent">
            {item.status === 'success'
              ? `Orchestration submitted successfully`
              : `Sorry, we couldn't submit your orchestration, please try again`}
          </span>
        </Notification>
      </div>
    </CsbErrorBoundary>
  );
};

import { SearchField, WkSwitch } from '@wk/components-react16';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { JOBS_OWNER_FILTER } from 'utils/common-constants';
import {
  resetPaginatorInfo,
  selectFilterStatus,
  setFilterInfo,
  setLabelForFirstStatus,
} from 'app/dashboard/DashboardSlice';
import { FC, useState } from 'react';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './TableActionsLeft.module.scss';

const TableActionsLeft: FC<any> = (props) => {
  const dispatch = useAppDispatch();
  const filterInfo = useAppSelector(selectFilterStatus);

  const [isMyOnly, setIsMyOnly] = useState(
    filterInfo?.jobsOwner === JOBS_OWNER_FILTER.USER_JOBS ? true : false
  );

  const onMyOnlyChanged = () => {
    dispatch(resetPaginatorInfo());
    const newStatus = !isMyOnly;
    setIsMyOnly(newStatus);
    const dataToSend = {
      ...filterInfo,
      ...{
        jobsOwner: newStatus
          ? JOBS_OWNER_FILTER.USER_JOBS
          : JOBS_OWNER_FILTER.ALL_JOBS,
      },
    };
    dispatch(setLabelForFirstStatus(newStatus ? 'My' : 'All'));
    dispatch(setFilterInfo(dataToSend));
  };

  return (
    <CsbErrorBoundary>
      <>
        <div
          className={style['table-actions-left']}
          data-testid="TableActionsLeft"
        >
          <div className={style['table-actions-left__switch-my']}>
            <WkSwitch
              name="my orchestrations"
              isChecked={isMyOnly}
              label="My orchestrations only"
              onChange={() => onMyOnlyChanged()}
            ></WkSwitch>
          </div>
        </div>
      </>
    </CsbErrorBoundary>
  );
};

export default TableActionsLeft;

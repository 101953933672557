import {
  IWorkflowProduct,
  IWorkflow,
  RUN_ORCHESTRARIONS_STATUS,
} from 'interfaces/runOrchestration/workflow-row';

export const mapProductToTable = (
  product: any,
  orchestrationParent: IWorkflow
): IWorkflowProduct => {
  return {
    id: product.productId + (Math.floor(Math.random() * 1000000) + 1),
    name: orchestrationParent.name,
    productName: product.name,
    isProduct: true,
    description: product.description,
    supportsPrioritization: orchestrationParent.supportsPrioritization,
    params: product.params,
    hasDynamicParameters: false,
    status: orchestrationParent.status,
  };
};

import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { FC, ReactNode } from 'react';
import style from './TableActionsContainer.module.scss';

interface ITableActionsContainerProps {
  children: {
    right?: ReactNode;
    left?: ReactNode;
  };
}

const TableActionsContainer: FC<ITableActionsContainerProps> = (
  props
): JSX.Element => {
  const { right, left } = props.children;

  return (
    <CsbErrorBoundary>
      <div
        className={style['table-actions-container']}
        data-testid="TableActionsContainer"
      >
        {left ? (
          <div className={style['table-actions-container-left']}>{left}</div>
        ) : null}
        {right ? (
          <div className={style['table-actions-container-right']}>{right}</div>
        ) : null}
      </div>
    </CsbErrorBoundary>
  );
};

export default TableActionsContainer;

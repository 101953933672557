import { ORCHESTRATION_STATUSES, STEP_STATUSES } from 'utils/common-constants';
import { IOrchestrationInstancePanel } from 'interfaces/orchestrationDiagram/orchestration-diagram';
import { calculateJobDurationIfApplies } from 'utils/commonFunctions/CommonFunctions';
import { DateUtils } from 'utils/dateUtils/DateUtils';

export const createOrchestrationInstanceSidePanelStep = (
  job: any
): IOrchestrationInstancePanel => {
  return {
    id: job?.jobId,
    name: job?.workflow?.name || '',
    startTime: job.startTime
      ? DateUtils.formatDateFromUtcString(job.startTime)
      : DateUtils.formatDateFromUtcString(job.submitTime),
    endTime:
      job.status !== ORCHESTRATION_STATUSES.PENDING
        ? DateUtils.formatDateFromUtcString(job.endTime)
        : '-',
    submitTime: DateUtils.formatDateFromUtcString(job?.submitTime) ?? '',
    duration: calculateJobDurationIfApplies(job),
    firstFailedStepId: findFirstFailedStep(job?.steps),
    inputName: job?.originalFileName,
    inputSize: formatInputSize(job?.fileSize),
    owner: job?.user?.email,
    isOpen: true,
    timeoutAfternH: job?.workflow?.timeoutAfter,
    supportsPrioritization: job?.workflow?.supportsPrioritization,
    orchestrationPriority: job?.priority,
  };
};

const formatInputSize = (inputSize: number) => {
  if (inputSize < 1000) {
    return `${inputSize.toFixed(2)} B`;
  } else if (inputSize > 1024 && inputSize < Math.pow(1024, 2)) {
    return `${(inputSize / 1024).toFixed(2)} KiB`;
  } else if (inputSize > Math.pow(1024, 2) && inputSize < Math.pow(1024, 3)) {
    return `${(inputSize / Math.pow(1024, 2)).toFixed(2)} MiB`;
  } else if (inputSize > Math.pow(1024, 3) && inputSize < Math.pow(1024, 4)) {
    return `${(inputSize / Math.pow(1024, 3)).toFixed(2)} GiB`;
  } else if (inputSize > Math.pow(1024, 4) && inputSize < Math.pow(1024, 5)) {
    return `${(inputSize / Math.pow(1024, 4)).toFixed(2)} TiB`;
  } else {
    return undefined;
  }
};

const findFirstFailedStep = (orchestrationSteps: any[]): string => {
  const failedStep = orchestrationSteps?.find(
    (step) =>
      step.status === STEP_STATUSES.FAILED ||
      step.status === STEP_STATUSES.FINISHED_ERROR
  );
  return failedStep?.workflowStepId?.toString() || null;
};

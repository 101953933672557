import { defaultFilterParams } from 'components/TableContainer/utils/filterUtils';
import { TABLE_FIELDS } from 'utils/common-constants';
import { ExtendedColDef } from 'interfaces/table.interface';

const DEFAULT_FILTER_MENU_ICON = '<i class="ag-icon ag-icon-menu"/>';

export const RUN_ORCHESTRATIONS_TABLE_COLUMNS: ExtendedColDef[] = [
  {
    headerName: 'Orchestration Name',
    field: TABLE_FIELDS.name,
    menuTabs: ['filterMenuTab'],
    filter: 'agSetColumnFilter',
    filterParams: {
      defaultToNothingSelected: true,
      ...defaultFilterParams,
    },
    icons: {
      menu: DEFAULT_FILTER_MENU_ICON,
    },
    minWidth: 430,
    maxWidth: 430,
    comparator: () => 0,
    cellRenderer: 'cellOrchestrationName',
    cellClass: 'csb-visible-tooltip',
  },
  {
    headerName: 'Orchestration Description',
    field: TABLE_FIELDS.description,
    minWidth: 400,
    menuTabs: ['filterMenuTab'],
    suppressSizeToFit: false,
    icons: {
      menu: DEFAULT_FILTER_MENU_ICON,
    },
    comparator: () => 0,
  },
  {
    headerName: 'Worker',
    field: TABLE_FIELDS.worker,
    minWidth: 150,
    maxWidth: 150,
    menuTabs: ['filterMenuTab'],
    suppressSizeToFit: false,
    icons: {
      menu: DEFAULT_FILTER_MENU_ICON,
    },
    comparator: () => 0,
  },
  {
    headerName: 'Throttle Rate',
    field: TABLE_FIELDS.throttleRate,
    minWidth: 140,
    maxWidth: 140,
    menuTabs: ['filterMenuTab'],
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: false,
    icons: {
      menu: DEFAULT_FILTER_MENU_ICON,
    },
    filterParams: {
      filterOptions: [
        {
          displayKey: 'greaterThan',
          displayName: 'Greater Than',
          predicate: () => true,
        },
        {
          displayKey: 'lessThan',
          displayName: 'Less Than',
          predicate: () => true,
        },
        {
          displayKey: 'inRange',
          displayName: 'In Range',
          predicate: () => true,
          numberOfInputs: 2,
        },
      ],
      ...defaultFilterParams,
    },
    comparator: () => 0,
  },
  {
    headerName: 'Status',
    field: TABLE_FIELDS.status,
    suppressSizeToFit: false,
    minWidth: 108,
    maxWidth: 108,
    menuTabs: [],
  },
  {
    headerName: '',
    field: TABLE_FIELDS.options,
    cellClass: 'csb-visible-cell',
    cellRenderer: 'cellOptions',
    suppressSizeToFit: false,
    minWidth: 60,
    maxWidth: 60,
    menuTabs: [],
  },
];

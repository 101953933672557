import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import OrchestrationFlowElement from 'components/OrchestrationFlowElement/OrchestrationFlowElement';
import React, { FC, useMemo } from 'react';
import ReactFlow, { MiniMap, Controls } from 'react-flow-renderer';
import style from './FlowChartDetails.module.scss';

interface FlowChartDetailsProps {
  nodes?: any;
  edges?: any;
}

const FlowChartDetails: FC<FlowChartDetailsProps> = ({ nodes, edges }) => {
  const nodeTypes = useMemo(
    () => ({ orchestrationFlowElement: OrchestrationFlowElement }),
    []
  );
  return (
    <CsbErrorBoundary>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        preventScrolling={false}
        panOnScroll={true}
        className={style['react-flow__chart-details']}
      >
        <Controls />
      </ReactFlow>
    </CsbErrorBoundary>
  );
};

export default FlowChartDetails;

import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { IOrchestrationRow } from '../../interfaces/dashboard/orchestration-row.interface';

interface CellLinkProps {
  data: IOrchestrationRow;
}

const CellLink: FC<any> = (props) => {
  const rowData: IOrchestrationRow = props.data;
  return (
    <CsbErrorBoundary>
      <div className="cell-link" data-testid="CellLink">
        <Link to={`orchestration/${rowData?.id}`}>{rowData?.id}</Link>
      </div>
    </CsbErrorBoundary>
  );
};

export default CellLink;

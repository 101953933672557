import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface IAuthBody {
  origin: string;
  code?: string | null;
}

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/csb',
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    postLogin: builder.mutation<any, any>({
      query: () => {
        const queryParams = new URLSearchParams(window.location.search);
        const dataToSend: IAuthBody = {
          origin: `${window.location.origin.toString()}/`,
        };

        if (queryParams.has('code')) {
          dataToSend.code = queryParams.get('code');
        }
        return {
          url: `ui/login`,
          method: 'POST',
          body: dataToSend,
        };
      },
    }),
    postLogout: builder.mutation<any, any>({
      query: () => {
        return {
          url: `ui/logout`,
          method: 'POST',
          body: {
            origin: `${window.location.origin.toString()}/`,
          },
        };
      },
    }),
  }),
});

export const { usePostLoginMutation, usePostLogoutMutation } = authApi;

import { ENVIROMENTS } from 'utils/common-constants';

export const useGetEnviroment = () => {
  const host = window.location.host;
  if (host.includes('stg-sbus-us-rc-apl')) {
    return ENVIROMENTS.NA_STAGE;
  } else if (host.includes('prd-sbus-us-rl-apl')) {
    return ENVIROMENTS.NA_PROD;
  } else if (host.includes('stg-sbus-eu-rc-apl')) {
    return ENVIROMENTS.EU_STAGE;
  } else if (host.includes('prd-sbus-eu-rl-apl')) {
    return ENVIROMENTS.EU_PROD;
  } else if (
    host.includes('dev-sbusui-us-asset-apl') ||
    host.includes('localhost')
  ) {
    return ENVIROMENTS.DEV_ENV;
  } else {
    return null;
  }
};

import {
  ButtonField,
  ButtonGroup,
  ButtonGroupItem,
  DropdownMenuItem,
  Iconsvg,
  Tooltip,
  TooltipBody,
} from '@wk/components-react16';
import style from './RunOrchestrationButtons.module.scss';
import { useTooltipControlled } from 'utils/hooks/useTooltipControlled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { openModal, setSidebarNavigated } from 'app/modal/ModalSlice';
import { selectSelectedWorkflow } from 'app/runOrchestrations/RunOrchestrationsSlice';
import { RUN_ORCHESTRARIONS_STATUS } from 'interfaces/runOrchestration/workflow-row';
import { useSubmitWorkflowMutation } from 'api/orchestrationApi';
import {
  formatJobRequest,
  showToastAndClose,
} from 'utils/commonFunctions/CommonFunctions';
import { useNavigate } from 'react-router-dom';
import { SPINE_MENU_ITEMS } from 'utils/common-constants';

const IMPORT = 'import';
const CREATE_PRODUCT = 'create-product';
const CONFIG_RUN = 'config-run';
const RUN = 'run';

const buttonsData = [
  {
    id: IMPORT,
    text: 'Import an orchestration template',
  },
  {
    id: CREATE_PRODUCT,
    text: 'Create a product variant of your orchestration',
  },
  {
    id: CONFIG_RUN,
    text: 'Configure and submit your orchestration',
  },
  {
    id: RUN,
    text: 'Submit your orchestration with its default configuration',
  },
];

export interface RunOrchestrationButtonsProps {
  isDevOrAdmin: boolean | undefined;
}

export const RunOrchestrationButtons = ({
  isDevOrAdmin,
}: RunOrchestrationButtonsProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [submitWokflow, { isLoading }] = useSubmitWorkflowMutation();
  const selectedOrchestration = useAppSelector(selectSelectedWorkflow);
  const { handleTooltip, getValue } = useTooltipControlled([
    IMPORT,
    CREATE_PRODUCT,
    CONFIG_RUN,
    RUN,
  ]);

  const handleConfigureAndRun = () => {
    dispatch(
      openModal({
        id: '1',
        componentName: 'modals/configureAndRun',
        title: `Upload files and configure orchestration`,
        footerName: 'cancel-next',
        display: true,
        componentProps: { data: { wizardStartingIndex: 0 } },
        width: '1500px',
      })
    );
  };

  const handleRun = async () => {
    if (selectedOrchestration) {
      try {
        const jobRequest = formatJobRequest({
          name: selectedOrchestration.name,
        });
        const responseId = await submitWokflow({
          ...jobRequest,
        }).unwrap();
        showToastAndClose(
          'success',
          'configureAndRunModal',
          (...args) => {},
          dispatch
        );
        navigate(`/dashboard/orchestration/${responseId}`);
        dispatch(setSidebarNavigated(SPINE_MENU_ITEMS.VIEW_ORCHESTRATION));
      } catch (error) {
        showToastAndClose(
          'error',
          'configureAndRunModal',
          (...args) => {},
          dispatch
        );
      }
    }
  };

  return (
    <div className={style['buttons']} data-testid="RunOrchestrationButtons">
      {isDevOrAdmin !== undefined && (
        <ButtonGroup mode="static">
          {isDevOrAdmin && (
            <ButtonGroupItem slot="buttonGroupItem">
              <ButtonField mode={'ghost'} iconPosition={'left'}>
                <button
                  type="button"
                  onClick={() => {}}
                  id="import"
                  data-testid="ImportBtn"
                  onMouseEnter={() => handleTooltip(IMPORT, true)}
                  onMouseLeave={() => handleTooltip(IMPORT, false)}
                  disabled={isLoading}
                >
                  <Iconsvg name="import"></Iconsvg>
                  Import
                </button>
              </ButtonField>
            </ButtonGroupItem>
          )}
          {isDevOrAdmin && (
            <ButtonGroupItem slot="buttonGroupItem">
              <ButtonField mode={'ghost'} iconPosition={'left'}>
                <button
                  type="button"
                  onClick={() => {}}
                  id="create-product"
                  data-testid="CreateProductBtn"
                  onMouseEnter={() => handleTooltip(CREATE_PRODUCT, true)}
                  onMouseLeave={() => handleTooltip(CREATE_PRODUCT, false)}
                  disabled={!selectedOrchestration || isLoading}
                >
                  <Iconsvg name="plus"></Iconsvg>
                  Create Product
                </button>
              </ButtonField>
            </ButtonGroupItem>
          )}
          <ButtonGroupItem slot="buttonGroupItem">
            <ButtonField iconPosition={'left'}>
              <button
                type="button"
                onClick={() => handleConfigureAndRun()}
                id="config-run"
                data-testid="ConfigureAndRunBtn"
                onMouseEnter={() => handleTooltip(CONFIG_RUN, true)}
                onMouseLeave={() => handleTooltip(CONFIG_RUN, false)}
                disabled={
                  !selectedOrchestration ||
                  selectedOrchestration.status ===
                    RUN_ORCHESTRARIONS_STATUS.INACTIVE ||
                  isLoading
                }
              >
                <Iconsvg name="play"></Iconsvg>
                Configure and run
              </button>
            </ButtonField>
          </ButtonGroupItem>
          <ButtonGroupItem slot="buttonGroupItem">
            <ButtonField iconPosition={'left'}>
              <button
                type="button"
                onClick={() => handleRun()}
                id="run"
                data-testid="runBtn"
                onMouseEnter={() => handleTooltip(RUN, true)}
                onMouseLeave={() => handleTooltip(RUN, false)}
                disabled={
                  !selectedOrchestration ||
                  selectedOrchestration.status ===
                    RUN_ORCHESTRARIONS_STATUS.INACTIVE ||
                  isLoading
                }
              >
                <Iconsvg name="play"></Iconsvg>
                Run
              </button>
            </ButtonField>
          </ButtonGroupItem>
        </ButtonGroup>
      )}
      {isDevOrAdmin !== undefined && (
        <>
          {buttonsData.map((item: any) => (
            <Tooltip
              position="bottom"
              targetSelector={`#${item.id}`}
              controlMode="controlled"
              isOpen={getValue(item.id)}
            >
              <TooltipBody slot="tooltipBody">{item.text}</TooltipBody>
            </Tooltip>
          ))}
        </>
      )}
    </div>
  );
};

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { IDateParams } from 'ag-grid-community';
import flatpickr from 'flatpickr';
import style from './DateTimeInput.module.scss';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { useAppSelector } from 'app/hooks';
import { selectFilterModel } from 'app/dashboard/DashboardSlice';

export default forwardRef((props: IDateParams, ref) => {
  const defaultDate = new Date(new Date().setHours(0, 0, 0, 0));
  const [date, setDate] = useState<Date>(defaultDate);
  const filterModelState = useAppSelector(selectFilterModel);
  const dateModified = useRef<boolean>(false);
  const timeModified = useRef<boolean>(false);
  const [time, setTime] = useState<string>('');
  const [picker, setPicker] = useState<any>(null);
  const [timePicker, setTimePicker] = useState<any>(null);
  const refFlatPickr = useRef(null);
  const refFlatTimePickr = useRef(null);
  const refInput = useRef<HTMLInputElement>(null);
  const refTimeInput = useRef<HTMLInputElement>(null);

  // we use a ref as well as state, as state is async,
  // and after the grid calls setDate() (eg when setting filter model)
  // it then can call getDate() immediately (eg to execute the filter)
  // and we need to pass back the most recent value, not the old 'current state'.
  const dateRef = useRef<any>(null);

  //*********************************************************************************
  //          LINKING THE UI, THE STATE AND AG-GRID
  //*********************************************************************************

  const onDateChanged = (selectedDates: any) => {
    const newDate = selectedDates[0];
    setDate(newDate);
    dateRef.current = newDate;
    props.onDateChanged();
    dateModified.current = true;
  };

  const onTimeChanged = (selectedDates: any) => {
    const newDate = selectedDates[0];
    setTime(newDate?.toString().slice(16, 21));
    dateRef.current = newDate;
    props.onDateChanged();
    timeModified.current = true;
  };

  useEffect(() => {
    setPicker(
      //@ts-ignore
      flatpickr(refFlatPickr.current, {
        onChange: onDateChanged,
        dateFormat: 'Y-m-d',
        wrap: true,
        defaultDate,
      })
    );

    setTimePicker(
      //@ts-ignore
      flatpickr(refFlatTimePickr.current, {
        onChange: onTimeChanged,
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: true,
        defaultDate: defaultDate?.toString().slice(16, 21),
      })
    );

    onDateChanged([defaultDate]);
    onTimeChanged([defaultDate]);

    return () => {};
  }, []);

  useEffect(() => {
    if (picker) {
      picker.calendarContainer.classList.add('ag-custom-component-popup');
    }

    if (timePicker) {
      timePicker.calendarContainer.classList.add('ag-custom-component-popup');
    }
    return () => {};
  }, [picker, timePicker]);

  useEffect(() => {
    //Callback after the state is set. This is where we tell ag-grid that the date has changed so
    //it will proceed with the filtering and we can then expect AG Grid to call us back to getDate
    if (picker) {
      picker.setDate(date);
      dateRef.current = date;
    }

    if (timePicker) {
      timePicker.setDate(time);
      dateRef.current = date;
    }
    return () => {};
  }, [date, time, timePicker, picker]);

  useImperativeHandle(ref, () => ({
    //*********************************************************************************
    //          METHODS REQUIRED BY AG-GRID
    //*********************************************************************************
    getDate() {
      //ag-grid will call us here when in need to check what the current date value is hold by this
      //component.
      dateModified.current = false;
      timeModified.current = false;
      return dateRef.current;
    },

    setDate(date: any) {
      //ag-grid will call us here when it needs this component to update the date that it holds.
      dateModified.current = false;
      timeModified.current = false;
      dateRef.current = date;
      setDate(date);
      setTime(date?.toString().slice(16, 21));
    },

    //*********************************************************************************
    //          AG-GRID OPTIONAL METHODS
    //*********************************************************************************

    setInputPlaceholder(placeholder: string) {
      handleInputPlaceholder();
    },
    setInputAriaLabel(label: string) {
      if (refInput.current) {
        refInput.current.setAttribute('aria-label', label);
      }
    },
  }));

  refInput.current?.addEventListener('click', () => {
    dateModified.current = true;
  });

  refTimeInput.current?.addEventListener('click', () => {
    timeModified.current = true;
  });

  const handleInputPlaceholder = () => {
    if (refInput.current) {
      const isFilterApplied =
        filterModelState && Object.keys(filterModelState).length > 0;

      if (
        (!dateModified.current || !timeModified.current) &&
        !isFilterApplied
      ) {
        !dateModified.current && setDate(new Date());
        !timeModified.current && setTime(defaultDate?.toString().slice(16, 21));
      } else if (!dateModified.current || !timeModified.current) {
        !dateModified.current && setDate(date);
        !timeModified.current && setTime(time);
      }
    }
  };

  // inlining styles to make simpler the component
  return (
    <CsbErrorBoundary>
      <>
        <div
          data-testid="DateTimeInput"
          className={style['custom-date-container']}
        >
          <div role="presentation" ref={refFlatPickr}>
            <input
              className={`ag-date-field-input ${style['custom-date-input']}`}
              type="text"
              ref={refInput}
              data-input
            />
            <div className="input-button" title="clear" data-clear>
              <i className="fa fa-times"></i>
            </div>
          </div>
          <div role="presentation" ref={refFlatTimePickr}>
            <input
              className={`ag-date-field-input ${style['custom-date-input']}`}
              type="text"
              ref={refTimeInput}
              value={time}
              aria-label="Filter Time Value"
              placeholder="Pick time..."
              readOnly
            />
            <div className="input-button" title="clear" data-clear>
              <i className="fa fa-times"></i>
            </div>
          </div>
        </div>
      </>
    </CsbErrorBoundary>
  );
});

import { STEP_STATUSES } from 'utils/common-constants';
import { mapOrchestrationStep } from 'dto/orchestrationDiagram/orchestrationSteps';
import { IOrchestrationFullStep } from 'interfaces/orchestrationDiagram/orchestration-step';
import {
  IEdges,
  IOrchestrationNode,
  IOrchestrationSidePanelSteps,
  stepTypes,
} from 'interfaces/orchestrationDiagram/orchestration-diagram';
import { DateUtils } from 'utils/dateUtils/DateUtils';
import { Useuid } from 'utils/hooks/useUid';

const CHILDS_STEP = 200;
const BRANCHES_CHILDS_STEP = 420;
const Y_AXIS_STEP = 86;
const X_AXIS_STEP = 560;
const INITIAL_Y_AXIS = 10;
let initialXAxis = 55;

//get initial steps and tag them with necesary properties
export const getSteps = (
  stepsGroup: any,
  acc: IOrchestrationFullStep[][],
  isNestedStep: boolean,
  stepType: stepTypes,
  nestGroupId?: any
): IOrchestrationFullStep[][] => {
  if (stepsGroup) {
    const newStep = stepsGroup.steps?.map((step: any) => ({
      ...step,
      isNestedStep,
      stepType,
      nestGroupId:
        step.nestGroupId?.length > 0
          ? [...step.nestGroupId, nestGroupId].flat()
          : [nestGroupId].flat(),
    }));
    acc.push(newStep);
    getNestedAndFailbackSteps(newStep, acc, isNestedStep, stepType);
    getSteps(
      stepsGroup.nextStepGroup,
      acc,
      isNestedStep,
      stepType,
      nestGroupId
    );
  }
  return acc;
};

const getNestedAndFailbackSteps = (
  newStep: any,
  acc: IOrchestrationFullStep[][],
  isNestedStep: boolean,
  stepType: stepTypes
) => {
  newStep?.map((step: any) => {
    const nestedGroupId =
      step.nestGroupId?.length > 0 &&
      step.nestGroupId.find((groupId: string) => groupId !== undefined)
        ? [...step.nestGroupId, step.workflowStepId.toString()]
        : [step.workflowStepId.toString()];
    if (step.failbackStepGroup) {
      getSteps(
        step.failbackStepGroup,
        acc,
        isNestedStep,
        stepType === 'globalFailback' ? stepType : 'failback',
        nestedGroupId
      );
    }
    if (step.nestedStepGroup) {
      getSteps(step.nestedStepGroup, acc, true, stepType, nestedGroupId);
    }
  });
};

export const generateEdges = (
  stepGroups: IOrchestrationFullStep[][]
): IEdges[] => {
  const edges: IEdges[][] = [];
  stepGroups.forEach((group, index) => {
    group.forEach((currentStep: IOrchestrationFullStep) => {
      if (currentStep == null) {
        return;
      }
      const nextNestedGroup = currentStep.nestedStepGroup?.steps;
      const nextFailBackGroup = currentStep.failbackStepGroup?.steps;
      const nextGroup = stepGroups
        .slice(index + 1)
        ?.find((steps: IOrchestrationFullStep[]) =>
          steps.find((step: IOrchestrationFullStep) => {
            const nestGroupIds = [...step.nestGroupId].reverse();
            return (
              step.isNestedStep === false ||
              nestGroupIds.find(
                (groupId: string) =>
                  groupId !== undefined &&
                  currentStep.nestGroupId.includes(groupId) &&
                  currentStep.workflowStepId > step.workflowStepId &&
                  Number(currentStep.stepIndex) < Number(step.stepIndex)
              )
            );
          })
        );
      if (nextFailBackGroup && nextFailBackGroup.length > 0) {
        const newEdge = setEdgesBasedOnStepGroup(
          nextFailBackGroup,
          currentStep,
          index,
          false
        );
        edges.push(newEdge);
      } else if (nextNestedGroup && nextNestedGroup.length > 0) {
        const newEdge = setEdgesBasedOnStepGroup(
          nextNestedGroup,
          currentStep,
          index,
          false
        );
        edges.push(newEdge);
      } else if (nextGroup && nextGroup?.length > 0) {
        const newEdge = setEdgesBasedOnStepGroup(
          nextGroup,
          currentStep,
          index,
          true
        );
        edges.push(newEdge);
      } else {
        return;
      }
    });
  });
  return edges.flat();
};

//Create all the nodes array with required position
export const createNodes = (
  job: any,
  containerWidth = 1000,
  resultSteps: IOrchestrationFullStep[][]
): {
  nodes: IOrchestrationNode[];
  orchestrarionSteps: IOrchestrationSidePanelSteps[];
} => {
  const workflow = job.workflow;
  const stepsObject: any = {};
  job.steps?.forEach((step: any) => {
    stepsObject[step.workflowStepId] = step;
  });
  let nodes: IOrchestrationNode[] = [];
  let level = 1;
  initialXAxis = containerWidth / 2 - 150;
  let positionX = initialXAxis;
  let positionY = INITIAL_Y_AXIS;
  const firstGlobalFailoverStep = false;
  const orchestrarionSteps: IOrchestrationSidePanelSteps[] = [];
  //Map to store nested childs position, avoiding wrong changes or unnecesary re calculations
  const nestedStepsMap: any = {};
  //Map to store the next X position of all nodes located in a Y position
  const posXMap: any = {};
  if (!workflow) {
    return { nodes, orchestrarionSteps };
  }
  const groups = resultSteps.map((group) => {
    if (group.length > 1) {
      // Modify X axis to ensure displaying the childs of a step in a centered way
      positionX =
        posXMap[positionY - Y_AXIS_STEP] !== undefined &&
        posXMap[positionY - Y_AXIS_STEP].used === true &&
        posXMap[positionY - Y_AXIS_STEP].isDefault === false
          ? posXMap[positionY - Y_AXIS_STEP].pos
          : positionX;
      positionX = positionX - (CHILDS_STEP + 100) * (group.length - 1);
    }
    const steps = generateSteps(
      group,
      firstGlobalFailoverStep,
      orchestrarionSteps,
      stepsObject,
      nestedStepsMap,
      posXMap,
      {
        positionY,
        positionX,
        level,
      }
    );
    positionX = initialXAxis;
    positionY += Y_AXIS_STEP;
    level = level + 1;
    return steps;
  });
  nodes = groups.flat();
  return { nodes, orchestrarionSteps };
};

const generateSteps = (
  group: IOrchestrationFullStep[],
  firstGlobalFailoverStep: boolean,
  orchestrarionSteps: IOrchestrationSidePanelSteps[],
  stepsObject: any,
  nestedStepsMap: any,
  posXMap: any,
  additionalData: {
    positionY: number;
    positionX: number;
    level: number;
  }
): IOrchestrationNode[] => {
  let { positionY, positionX } = additionalData;
  const { level } = additionalData;
  return group.map((step: IOrchestrationFullStep) => {
    let node: IOrchestrationNode;
    if (
      step.stepType === 'globalFailback' &&
      firstGlobalFailoverStep === false
    ) {
      firstGlobalFailoverStep = true;
      positionY += 50;
    }
    if (
      nestedStepsMap[step.workflowStepId.toString()] !== undefined &&
      nestedStepsMap[step.workflowStepId.toString()].data.isNestedStep
    ) {
      node = nestedStepsMap[step.workflowStepId.toString()];
      checkForNestedSteps(
        node.position?.x,
        node.position?.y,
        step,
        stepsObject,
        level,
        nestedStepsMap,
        posXMap
      );
    } else {
      if (
        posXMap[positionY] &&
        posXMap[positionY]?.step !== step.workflowStepId
      ) {
        positionX = posXMap[positionY].isDefault
          ? positionX
          : posXMap[positionY].pos;
      }
      node = createNode(step, stepsObject, level, positionX, positionY, false);
      checkForNestedSteps(
        positionX,
        positionY,
        step,
        stepsObject,
        level,
        nestedStepsMap,
        posXMap
      );
    }
    positionX += X_AXIS_STEP;
    orchestrarionSteps.push(
      mapOrchestrationStep(step, stepsObject[step.workflowStepId])
    );
    return node;
  });
};

export function setEdgesBasedOnStepGroup(
  stepsGroup: IOrchestrationFullStep[],
  currentStep: IOrchestrationFullStep,
  stepIndex: number,
  avoidNested: boolean
): IEdges[] {
  return stepsGroup
    .map((step: IOrchestrationFullStep) => {
      if (
        avoidNested === true &&
        step.isNestedStep === true &&
        step.failbackStepGroup !== null
      ) {
        return null;
      } else if (
        currentStep.workflowStepId?.toString() !==
        step.workflowStepId?.toString()
      ) {
        return {
          id: `edge-${Useuid()}`,
          source: currentStep.workflowStepId?.toString(),
          target: step.workflowStepId?.toString(),
          sourceHandle: 'bottom',
          type: 'step',
          className: 'csb-custom-edge',
        } as IEdges;
      } else {
        return null;
      }
    })
    .filter((edge) => edge != null) as IEdges[];
}

export function createNode(
  step: IOrchestrationFullStep,
  stepsObject: any,
  level: number,
  positionX: number,
  positionY: number,
  isNestedStep: boolean
): IOrchestrationNode {
  const stepExtraInfo = stepsObject[step.workflowStepId];
  return {
    id: step.workflowStepId.toString(),
    type: 'orchestrationFlowElement',
    data: {
      level,
      name: step.stepTitle || '',
      id: step.workflowStepId.toString(),
      moduleName: step.module?.name,
      description: `${step.module?.description}`,
      duration:
        stepExtraInfo?.status === STEP_STATUSES.NOT_STARTED
          ? '-'
          : DateUtils.getFormattedDiff(
              stepExtraInfo?.startTime,
              stepExtraInfo?.endTime || new Date().toISOString()
            ) || '1s',
      status: stepExtraInfo?.status,
      index: step.stepIndex,
      isNestedStep,
      stepType: step.stepType,
      version: step.module?.version,
      isConditional:
        step.stepCondition != null && step.stepCondition !== '' ? true : false,
    },
    position: { x: positionX, y: positionY },
  };
}

export function checkForNestedSteps(
  positionX: number,
  positionY: number,
  step: IOrchestrationFullStep,
  stepsObject: any,
  level: number,
  nestedStepsMap: any,
  posXMap: any
) {
  if (step.failbackStepGroup || step.nestedStepGroup) {
    const nestedSteps = step.failbackStepGroup ?? step.nestedStepGroup;
    const nestedStepsResult: IOrchestrationFullStep[][] = [];
    const tempPositionY = positionY + Y_AXIS_STEP;
    const tempPositionX = positionX;
    const tempX = positionX;
    getSteps(
      nestedSteps,
      nestedStepsResult,
      true,
      step.failbackStepGroup ? 'failback' : step.stepType
    );
    //Increase X axis in the posXMap when having multiple steps in the same Y axis
    fillXPositionMap(
      nestedStepsResult,
      posXMap,
      tempPositionY,
      tempPositionX,
      step,
      level
    );
    addNestedStepIntoMap(
      nestedStepsResult,
      positionX,
      stepsObject,
      posXMap,
      nestedStepsMap,
      level,
      {
        tempPositionX,
        tempPositionY,
        tempX,
      }
    );
  }
}

const addNestedStepIntoMap = (
  nestedStepsResult: IOrchestrationFullStep[][],
  positionX: number,
  stepsObject: any,
  posXMap: any,
  nestedStepsMap: any,
  level: number,
  location: {
    tempPositionX: number;
    tempPositionY: number;
    tempX: number;
  }
) => {
  let { tempPositionX, tempPositionY, tempX } = location;
  nestedStepsResult.forEach((nestedStepGroup: IOrchestrationFullStep[]) => {
    if (nestedStepGroup.length > 1) {
      // Modify X axis to ensure displaying the childs siblings of a step in a centered way
      tempX =
        posXMap[tempPositionY - Y_AXIS_STEP] !== undefined &&
        posXMap[tempPositionY - Y_AXIS_STEP].used === true &&
        posXMap[tempPositionY - Y_AXIS_STEP].isDefault === false
          ? posXMap[tempPositionY - Y_AXIS_STEP].pos
          : tempX;
      tempPositionX = tempX - CHILDS_STEP * (nestedStepGroup.length - 1);
    } else {
      // Normal display of step ( only 1 child )
      tempPositionX =
        positionX !== initialXAxis &&
        posXMap[tempPositionY] !== undefined &&
        posXMap[tempPositionY].used === true &&
        posXMap[tempPositionY].isDefault === false
          ? posXMap[tempPositionY].pos
          : tempX;
    }
    nestedStepGroup.forEach((nestedStep: IOrchestrationFullStep) => {
      const nestedNodeResult = createNode(
        nestedStep,
        stepsObject,
        level,
        tempPositionX,
        tempPositionY,
        true
      );
      nestedStepsMap[nestedStep.workflowStepId.toString()] = nestedNodeResult;
      tempPositionX += X_AXIS_STEP;
    });
    tempPositionY += Y_AXIS_STEP;
  });
};

//Increase X axis in the posXMap when having multiple steps in the same Y axis
const fillXPositionMap = (
  steps: IOrchestrationFullStep[][],
  posXMap: any,
  posY: number,
  posX: number,
  actualStep: IOrchestrationFullStep,
  level: number
) => {
  steps.forEach((nestedGroup: IOrchestrationFullStep[]) => {
    if (nestedGroup.length > 0) {
      if (nestedGroup.length > 1) {
        posXMap[posY - Y_AXIS_STEP] = {
          pos: posX + BRANCHES_CHILDS_STEP * (nestedGroup.length + level),
          used: posXMap[posY - Y_AXIS_STEP]?.used ?? false,
          isDefault: false,
          step: actualStep.workflowStepId,
        };
      } else {
        posXMap[posY - Y_AXIS_STEP] = {
          pos: posX,
          used: true,
          isDefault: true,
          step: actualStep.workflowStepId,
        };
      }
    }
    posY += Y_AXIS_STEP;
  });
};

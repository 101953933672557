import {
  Breadcrumb,
  BreadcrumbBack,
  BreadcrumbItem,
  BreadcrumbItemLink,
  BreadcrumbItemText,
  ButtonFieldIcon,
  Iconsvg,
  Tag,
} from '@wk/components-react16';
import { usePostJobsMutation } from 'api/orchestrationApi';
import {
  composeFilterInfo,
  setPaginatorInfo,
  setSelectedOrchestrations,
} from 'app/dashboard/DashboardSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { ORCHESTRATION_STATUSES, PAGE } from 'utils/common-constants';
import { IDashboardState } from 'interfaces/dashboard/dashboard-slice.interface';
import { IOrchestrationRow } from 'interfaces/dashboard/orchestration-row.interface';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DateUtils } from 'utils/dateUtils/DateUtils';
import style from './OrchestrationBanner.module.scss';

const COLOR_BY_STATUS: Record<string, any> = {
  [ORCHESTRATION_STATUSES.FAILED]: 'red',
  [ORCHESTRATION_STATUSES.PENDING]: 'black',
  [ORCHESTRATION_STATUSES.IN_PROGRESS]: 'orange',
  [ORCHESTRATION_STATUSES.STOPPED]: 'purple',
  [ORCHESTRATION_STATUSES.STOPPING]: 'purple',
  [ORCHESTRATION_STATUSES.FINISHED]: 'green',
  [ORCHESTRATION_STATUSES.FINISHED_ERROR]: 'green',
};
const NAME_BY_STATUS: Record<ORCHESTRATION_STATUSES, any> = {
  [ORCHESTRATION_STATUSES.FAILED]: 'FAILED',
  [ORCHESTRATION_STATUSES.PENDING]: 'PENDING',
  [ORCHESTRATION_STATUSES.IN_PROGRESS]: 'IN PROGRESS',
  [ORCHESTRATION_STATUSES.STOPPED]: 'STOPPED',
  [ORCHESTRATION_STATUSES.STOPPING]: 'STOPPING',
  [ORCHESTRATION_STATUSES.FINISHED]: 'FINISHED',
  [ORCHESTRATION_STATUSES.FINISHED_ERROR]: 'FINISHED WITH ERRORS',
};

interface IOrchestrationBanner {
  selectedOrchestration: IOrchestrationRow | undefined;
}

const OrchestrationBanner = ({
  selectedOrchestration,
}: IOrchestrationBanner) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const dashboardStatus: IDashboardState = useAppSelector(
    (state: RootState) => state.dashboardContainer
  );
  const [navigateDirection, setNavegateDirection] = useState<
    'foward' | 'back'
  >();
  const pagesInfo = dashboardStatus.paginatorInfo;
  const orchestrationId = location.pathname.split(/[/]+/).pop() || '';
  const lastPage = useRef(
    Math.ceil((dashboardStatus.selectedStatus.count ?? 1) / pagesInfo.pageSize)
  );
  const indexOrchestration = useRef(0);
  const items = [
    { id: 'status', name: PAGE.ORCHESTRATION_STATUS, href: '/' },
    { id: 'details', name: PAGE.ORCHESTRATION_DETAILS, isText: true },
  ];

  useEffect(() => {
    indexOrchestration.current = dashboardStatus.orchestrations.findIndex(
      (x) => x.id.toString() === orchestrationId
    );
  }, []);

  useEffect(() => {
    switch (navigateDirection) {
      case 'back':
        navigate(`/orchestration/${dashboardStatus.orchestrations[0].id}`);
        break;
      case 'foward':
        navigate(
          `/orchestration/${
            dashboardStatus.orchestrations[
              dashboardStatus.orchestrations.length - 1
            ].id
          }`
        );
        break;
      default:
        break;
    }
  }, [dashboardStatus.orchestrations]);

  const changeOrchestration = (direccion: 'foward' | 'back') => {
    indexOrchestration.current = dashboardStatus.orchestrations.findIndex(
      (x) => x.id.toString() === orchestrationId
    );
    setNavegateDirection(direccion);
    if (direccion === 'back') {
      indexOrchestration.current = indexOrchestration.current + 1;
      if (dashboardStatus.orchestrations[indexOrchestration.current]) {
        navigate(
          `/dashboard/orchestration/${
            dashboardStatus.orchestrations[indexOrchestration.current].id
          }`
        );
      } else {
        if (pagesInfo.page < lastPage.current) {
          dispatch(
            setPaginatorInfo({ ...pagesInfo, page: pagesInfo.page + 1 })
          );
          dispatch(composeFilterInfo());
        }
      }
    } else {
      indexOrchestration.current = indexOrchestration.current - 1;
      if (dashboardStatus.orchestrations[indexOrchestration.current]) {
        navigate(
          `/dashboard/orchestration/${
            dashboardStatus.orchestrations[indexOrchestration.current].id
          }`
        );
      } else {
        if (pagesInfo.page > 1) {
          dispatch(
            setPaginatorInfo({ ...pagesInfo, page: pagesInfo.page - 1 })
          );
          dispatch(composeFilterInfo());
        }
      }
    }
  };

  const goBack = () => {
    dispatch(setSelectedOrchestrations([]));
    navigate('/dashboard');
  };

  return (
    <CsbErrorBoundary>
      <section
        className={style['orchestration-banner']}
        data-testid="OrchestrationBanner"
      >
        <div style={{ marginLeft: '20px', marginTop: '20px' }}>
          <Breadcrumb showLastChevron={false}>
            <BreadcrumbBack slot="breadcrumbBack">
              <ButtonFieldIcon>
                <button
                  onClick={goBack}
                  disabled={selectedOrchestration === undefined}
                  data-testid="BackBtn"
                >
                  <Iconsvg name="arrow-left"></Iconsvg>
                </button>
              </ButtonFieldIcon>
            </BreadcrumbBack>
            {items.map((item, index) => (
              <span slot="breadcrumbTooltipItem" key={index}>
                {item.name}
              </span>
            ))}
            {MapItems(items, goBack, selectedOrchestration)}
          </Breadcrumb>
        </div>
        <div className={style['orchestration-banner__container']}>
          <article className={style['orchestration-banner__title']}>
            <span className={style['orchestration-banner__title-text']}>
              {selectedOrchestration?.name}
            </span>
            {selectedOrchestration?.originalStatus && (
              <Tag
                color={
                  COLOR_BY_STATUS[selectedOrchestration?.originalStatus || '']
                }
                type="badge"
              >
                {NAME_BY_STATUS[selectedOrchestration.originalStatus]}
              </Tag>
            )}
          </article>
          <article className={style['orchestration-banner__chevron-container']}>
            <button
              type="button"
              className={`${style['orchestration-banner__chevron']} ${style['orchestration-banner__chevron-back']}`}
              onClick={() => changeOrchestration('foward')}
              disabled={
                dashboardStatus.loading ||
                (indexOrchestration.current === 0 && pagesInfo.page === 1)
              }
            >
              <Iconsvg name="chevron-left"></Iconsvg>
            </button>
            <button
              type="button"
              className={style['orchestration-banner__chevron']}
              onClick={() => changeOrchestration('back')}
              disabled={
                dashboardStatus.loading ||
                (indexOrchestration.current ===
                  dashboardStatus.orchestrations.length - 1 &&
                  pagesInfo.page === lastPage.current)
              }
            >
              <Iconsvg name="chevron-right"></Iconsvg>
            </button>
          </article>
          <article className={style['orchestration-banner__description']}>
            Job ID: {selectedOrchestration?.id} &emsp; Start time:{' '}
            {selectedOrchestration?.startTime || '-'} &emsp; End time:{' '}
            {selectedOrchestration?.endTime || '-'} &emsp; Duration:{' '}
            {calculateDuration(selectedOrchestration)} &emsp; Job started by:{' '}
            {selectedOrchestration?.user?.name.startsWith('Automa')
              ? 'Automated'
              : selectedOrchestration?.user?.name ?? '-'}
          </article>
        </div>
      </section>
    </CsbErrorBoundary>
  );
};

function MapItems(
  items: any,
  goBack: () => void,
  selectedOrchestration: IOrchestrationRow | undefined
) {
  return items.map((item: any, index: number) => {
    let result;
    if (item.isText) {
      result = (
        <BreadcrumbItemText>
          <span slot="breadcrumbItemLink" style={{ fontSize: '12px' }}>
            {item.name}
          </span>
        </BreadcrumbItemText>
      );
    } else {
      result = (
        <BreadcrumbItemLink
          isPreventDefault
          onLinkClick={goBack}
          isDisabled={selectedOrchestration === undefined}
        >
          <span
            slot="breadcrumbItemLink"
            style={{ fontSize: '12px', cursor: 'pointer' }}
            data-testid={`BreadCrumItemLink-${index}`}
          >
            {item.name}
          </span>
        </BreadcrumbItemLink>
      );
    }
    return (
      <BreadcrumbItem slot="breadcrumbItem" key={index}>
        {result}
      </BreadcrumbItem>
    );
  });
}

function calculateDuration(orchestration: IOrchestrationRow | undefined) {
  if (!orchestration) {
    return '-';
  }
  if (
    orchestration.originalStatus === ORCHESTRATION_STATUSES.STOPPING ||
    orchestration.originalStatus === ORCHESTRATION_STATUSES.STOPPED
  ) {
    return '-';
  }

  if (orchestration.originalStartTime && !orchestration.originalEndTime) {
    return DateUtils.getFormattedDiff(
      orchestration.originalStartTime,
      new Date().toISOString()
    );
  }
  return orchestration.originalStartTime && orchestration.originalEndTime
    ? DateUtils.getFormattedDiff(
        orchestration.originalStartTime,
        orchestration.originalEndTime
      ) || '1s'
    : '-';
}

export default OrchestrationBanner;

export interface IWorkflow {
  id: number;
  name: string;
  isProduct: boolean;
  status?: RUN_ORCHESTRARIONS_STATUS;
  description?: string;
  products?: {
    name: string;
    businessProduct?: any;
    cronTimezone?: any;
    cronTrigger?: any;
    eventParametersMapping?: any;
    ftpDropzone?: any;
    notifyEmails?: any;
    params?: any[];
    productId?: number;
    reportingIndex?: any;
    s3Dropzone?: any;
    sftpDropzone?: any;
    sftpPrivateKey?: any;
    unpackInput?: boolean;
    workflowId?: number;
  }[];
  worker?: string;
  throttleRate?: string;
  steps?: any;
  supportsPrioritization?: boolean;
  hasDynamicParameters?: boolean;
}

export interface IWorkflowProduct extends IWorkflow {
  productName: string;
  params: any[];
}

export enum RUN_ORCHESTRARIONS_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { useEffect, useState } from 'react';
import style from './CellOrchestrationName.module.scss';
import { useDispatch } from 'react-redux';
import { RadioField } from '@wk/components-react16/dist/radio-field/radio-field';
import { Iconsvg } from '@wk/components-react16/dist/iconsvg/iconsvg';
import {
  IWorkflowProduct,
  IWorkflow,
} from 'interfaces/runOrchestration/workflow-row';
import {
  removeWorkflowProducts,
  addWorkflowProducts,
  setSelectedWorkflow,
} from 'app/runOrchestrations/RunOrchestrationsSlice';
import { Tooltip } from '@wk/components-react16/dist/tooltip/tooltip';
import { TooltipBody } from '@wk/components-react16';

interface ICellOrchestrationName {
  data: Partial<IWorkflowProduct>;
}

const CellOrchestrationName = (props: ICellOrchestrationName) => {
  const rowData: Partial<IWorkflowProduct> = props.data;
  const dispatch = useDispatch();
  const [isChevronOpen, setIsChevronOpen] = useState<any>(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const products = rowData.products ?? [];
  const name = rowData.isProduct ? rowData.productName : rowData.name;

  useEffect(() => {
    if (isChevronOpen === true) {
      dispatch(addWorkflowProducts(rowData as IWorkflow));
    } else if (isChevronOpen === false) {
      dispatch(removeWorkflowProducts({ id: rowData.id, products }));
    }
  }, [isChevronOpen]);

  const selectOrchestration = () => {
    dispatch(setSelectedWorkflow(rowData as any));
  };

  return (
    <CsbErrorBoundary>
      <div
        className={style['cell-container']}
        data-testid="CellOrchestrationName"
        id={`name-${rowData.id}`}
        onMouseEnter={() => setIsTooltipOpen(true)}
        onMouseLeave={() => setIsTooltipOpen(false)}
        onClick={() =>
          setIsChevronOpen((isChevronOpen: any) =>
            isChevronOpen === null ? true : !isChevronOpen
          )
        }
      >
        {products?.length > 0 && (
          <div className={style['cell-chevron']}>
            <div>
              {isChevronOpen ? (
                <Iconsvg name="chevron-down"></Iconsvg>
              ) : (
                <Iconsvg name="chevron-right"></Iconsvg>
              )}
            </div>
          </div>
        )}
        <div
          className={
            rowData.isProduct
              ? style['cell-product']
              : style['cell-orchestration']
          }
        >
          <RadioField>
            <input
              type="radio"
              name="cellNameRadioBtn"
              onChange={() => selectOrchestration()}
            />
          </RadioField>
        </div>
        <span>{name}</span>
        {name && name?.length > 54 && (
          <Tooltip
            position="right"
            targetSelector={`#name-${rowData.id}`}
            id={`name-${rowData.id}-tooltip`}
            controlMode="controlled"
            isOpen={isTooltipOpen}
          >
            <TooltipBody slot="tooltipBody">
              <span data-testid="CellStatusTooltip">
                <span id={`name-${rowData.id}`}>{name}</span>
              </span>
            </TooltipBody>
          </Tooltip>
        )}
      </div>
    </CsbErrorBoundary>
  );
};

export default CellOrchestrationName;

import { orchestrationApi } from '../../api/orchestrationApi';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPaginatorStatusInterface } from '../../interfaces/paginator-status.interface';
import { RootState } from 'app/store';
import { mapProductToTable } from 'dto/runOrchestration/productToTable';
import { IRunOrchestrationSlice } from 'interfaces/runOrchestration/run-orchestration-state.interface';
import { mapWorkflowToTable } from 'dto/runOrchestration/workflowToTable';
import {
  IWorkflowProduct,
  IWorkflow,
} from 'interfaces/runOrchestration/workflow-row';

export const SLICE_KEY = 'runOrchestrations';

const initialState: IRunOrchestrationSlice = {
  loading: false,
  workflows: [],
  filterModel: null,
  selectedWorkflow: null,
  wizardActiveIndex: 0,
};

const runOrchestrationsSlice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    setWizardActiveIndex: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        wizardActiveIndex: state.wizardActiveIndex <= 3 ? action.payload : 3,
      };
    },
    startLoading: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    addWorkflowProducts: (state, action: PayloadAction<IWorkflow>) => {
      const orchestrationParent = action.payload;
      const parentIndex = state.workflows?.findIndex(
        (o) => o.id === orchestrationParent.id
      );
      const newProductsWorkflows: IWorkflowProduct[] =
        orchestrationParent.products?.map((product: any) =>
          mapProductToTable(product, orchestrationParent)
        ) ?? [];
      let newWorkflows = [...state.workflows];
      if (
        parentIndex > -1 &&
        newWorkflows?.length > 0 &&
        newProductsWorkflows?.length > 0
      ) {
        newWorkflows.splice(parentIndex + 1, 0, ...newProductsWorkflows);
      }
      return {
        ...state,
        workflows: newWorkflows,
      };
    },
    removeWorkflowProducts: (state, action: PayloadAction<any>) => {
      const { id, products } = action.payload;
      const parentIndex = state.workflows?.findIndex((o) => o.id === id);
      let newWorkflows = [...state.workflows];

      if (parentIndex > -1 && products?.length > 0) {
        newWorkflows.splice(parentIndex + 1, products?.length);
      }
      return {
        ...state,
        workflows: newWorkflows,
      };
    },
    setSelectedWorkflow: (
      state,
      action: PayloadAction<IWorkflow | IWorkflowProduct | null>
    ) => {
      return {
        ...state,
        selectedWorkflow: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      orchestrationApi.endpoints.getWorkflows.matchPending,
      (state) => {
        return {
          ...state,
          loading: true,
        };
      }
    );

    builder.addMatcher(
      orchestrationApi.endpoints.getWorkflows.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        const workflows: any[] = action.payload.map((workflow: any) =>
          mapWorkflowToTable(workflow)
        );
        workflows.forEach((workflow: any) =>
          workflow.products?.forEach((p: any) => mapProductToTable(p, workflow))
        );
        return {
          ...state,
          loading: false,
          workflows,
        };
      }
    );

    builder.addMatcher(
      orchestrationApi.endpoints.getWorkflows.matchRejected,
      (state) => {
        return {
          ...state,
          loading: false,
        };
      }
    );
  },
});

export const selectFilterModelRunOrchestrations = (state: RootState) =>
  state.runOrchestrations.filterModel;

export const selectWorkflows = (state: RootState) =>
  state.runOrchestrations.workflows;
export const selectRunOrchestrationLoading = (state: RootState) =>
  state.runOrchestrations.loading;

export const selectSelectedWorkflow = (state: RootState) =>
  state.runOrchestrations.selectedWorkflow;

export const selectWizardActiveIndex = (state: RootState) =>
  state.runOrchestrations.wizardActiveIndex;

export type SelectFilterModelType = ReturnType<
  typeof selectFilterModelRunOrchestrations
>;
export type SelectWorkflowsType = ReturnType<typeof selectWorkflows>;
export type SelectSelectedWorkflowType = ReturnType<
  typeof selectSelectedWorkflow
>;

export const {
  setWizardActiveIndex,
  addWorkflowProducts,
  removeWorkflowProducts,
  startLoading,
  setSelectedWorkflow,
} = runOrchestrationsSlice.actions;

export default runOrchestrationsSlice.reducer;

import { usePostLoginMutation, usePostLogoutMutation } from 'api/authApi';
import OrchestrationDetails from 'pages/OrchestrationDetails/OrchestrationDetails';
import { useEffect, useRef } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAppSelector } from './app/hooks';
import { RootState } from './app/store';
import HeaderCsb from './components/HeaderCsb/HeaderCsb';
import SpineMenuCsb from './components/SpineMenuCsb/SpineMenuCsb';
import Dashboard from './pages/Dashboard/Dashboard';
import { LicenseManager } from 'ag-grid-enterprise';
import Config from './config.json';
import {
  currentClearTimeout,
  currentSetTimeout,
} from 'utils/commonFunctions/CommonFunctions';
import RunOrchestrations from 'pages/RunOrchestration/RunOrchestrations';
const EVENTS_FOR_LOGOUT = [
  'load',
  'mousemove',
  'mousedown',
  'click',
  'scroll',
  'keypress',
];

const DEFAULT_TIMEOUT = 60 * 15 * 1000;

function App(): JSX.Element {
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const logoutTimer: any = useRef(null);
  const [login] = usePostLoginMutation();
  const [logout] = usePostLogoutMutation();

  useEffect(() => {
    login(null);
    LicenseManager.setLicenseKey(Config?.REACT_APP_AG_GRID_LICENCE);
  }, []);

  // Effect for auto logout
  useEffect(() => {
    const resetTimeout = (e: any) => {
      currentClearTimeout(logoutTimer);
      currentSetTimeout(
        logoutTimer,
        () => {
          logout(null);
        },
        DEFAULT_TIMEOUT
      );
    };

    for (const event of EVENTS_FOR_LOGOUT) {
      window.addEventListener(event, resetTimeout);
    }

    return () => {
      for (const event of EVENTS_FOR_LOGOUT) {
        window.removeEventListener(event, resetTimeout);
      }
    };
  }, []);

  return (
    <div>
      {isLoggedIn && (
        <>
          <HeaderCsb />
          <div className="App">
            <BrowserRouter>
              <SpineMenuCsb>
                <Routes>
                  <Route
                    path="/dashboard/orchestration/:id"
                    element={<OrchestrationDetails />}
                  />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route
                    path="/run-orchestrations"
                    element={<RunOrchestrations />}
                  />
                </Routes>
              </SpineMenuCsb>
            </BrowserRouter>
          </div>
        </>
      )}
    </div>
  );
}

export default App;

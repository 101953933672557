import {
  Iconsvg,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@wk/components-react16';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { ICloseModalOptions } from 'interfaces/modals/close-modal-info.interface';
import React, { FC, lazy, Suspense } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { closeModal } from '../../../app/modal/ModalSlice';
import { MODAL_FORMS_LIST, MODAL_FOOTER_LIST } from '../modal-list';

const ModalContainer: FC<any> = () => {
  const dispatch = useAppDispatch();
  const modalInfo = useAppSelector((state) => state.modal);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();
  const CustomComponent = lazy(MODAL_FORMS_LIST[modalInfo.componentName]);
  const CustomFooter = lazy(
    MODAL_FOOTER_LIST[modalInfo.footerName ?? 'cancel-reset-apply']
  );
  const customRef: React.RefObject<any> = React.createRef();
  const resetRef = React.createRef();
  const applyRef = React.createRef();

  const handleCloseModal = (event: ICloseModalOptions) => {
    reset();
    dispatch(
      closeModal({
        id: modalInfo.id,
        action: event,
      })
    );
  };

  const checkIfValid = (apply: boolean, reset: boolean) => {
    if (resetRef.current && applyRef.current) {
      (resetRef.current as HTMLButtonElement).disabled = !reset;
      (applyRef.current as HTMLButtonElement).disabled = !apply;
    } else {
      setTimeout(() => {
        checkIfValid(apply, reset);
      }, 100);
    }
  };

  return (
    <CsbErrorBoundary>
      <div data-testid="ModalElement">
        <Modal
          isOpen={modalInfo.display}
          onUserRequest={(event: ICloseModalOptions) => handleCloseModal(event)}
          width={modalInfo.width}
        >
          <ModalHeader
            titleId={'modal-id-001'}
            descriptionId={'modal-id-002'}
            onUserRequest={(event: ICloseModalOptions) =>
              handleCloseModal(event)
            }
            isFullscreenButtonVisible
          >
            {modalInfo.title}
          </ModalHeader>
          <CsbErrorBoundary>
            <ModalBody>
              <Suspense fallback={<Iconsvg name="spinner" isSpin></Iconsvg>}>
                {modalInfo.componentName ? (
                  <CustomComponent
                    {...modalInfo.componentProps}
                    ref={customRef}
                    onValueChanges={checkIfValid}
                    register={register}
                    setValue={setValue}
                    setError={setError}
                    clearErrors={clearErrors}
                    errors={errors}
                  />
                ) : (
                  modalInfo.body
                )}
              </Suspense>
            </ModalBody>
          </CsbErrorBoundary>
          <ModalFooter>
            {modalInfo.withFooter ? (
              <Suspense fallback={<Iconsvg name="spinner" isSpin></Iconsvg>}>
                <CustomFooter
                  {...modalInfo.componentProps}
                  ref={{ resetRef, applyRef, customRef }}
                  handleSubmit={handleSubmit}
                  reset={reset}
                  setError={setError}
                  clearErrors={clearErrors}
                  errors={errors}
                />
              </Suspense>
            ) : null}
          </ModalFooter>
        </Modal>
      </div>
    </CsbErrorBoundary>
  );
};

export default ModalContainer;

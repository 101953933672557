import { Spine, TopNavigationItem } from '@wk/components-react16';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectSidebarNavigated,
  setSidebarNavigated,
} from 'app/modal/ModalSlice';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SPINE_MENU_ITEMS } from 'utils/common-constants';

const MAX_WIDTH = 450;

const SpineMenuCsb = (props: any) => {
  const viewOrchestrationItem = {
    id: SPINE_MENU_ITEMS.VIEW_ORCHESTRATION,
    text: 'Orchestration Status',
    icon: 'list-bullet-check',
    href: '/dashboard',
  };
  const runOrchestrationItem = {
    id: SPINE_MENU_ITEMS.RUN_ORCHESTRATION,
    text: 'Run Orchestration',
    icon: 'play',
    href: '/run-orchestration',
  };
  const editOrchestrationItem = {
    id: SPINE_MENU_ITEMS.EDIT_ORCHESTRATION,
    text: 'Design Orchestration',
    icon: 'pencil',
    href: '/dashboard',
  };
  const manageModulesItem = {
    id: SPINE_MENU_ITEMS.MANAGE_MODULES,
    text: 'Manage Modules',
    icon: 'cubes',
    href: '/dashboard',
  };
  const closedItems = [
    viewOrchestrationItem,
    runOrchestrationItem,
    editOrchestrationItem,
    manageModulesItem,
  ];
  const openedItems = [
    { ...viewOrchestrationItem, text: 'Orchestration Status' },
    { ...runOrchestrationItem, text: 'Run Orchestration' },
    { ...editOrchestrationItem, text: 'Design Orchestration' },
    { ...manageModulesItem, text: 'Manage Modules' },
  ];
  const [items, setItems] = useState<TopNavigationItem[]>(closedItems);
  const [width, setWidth] = useState(272);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState('1');
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const navigationFromModal = useAppSelector(selectSidebarNavigated);
  const onlyVisualSelection = useRef(false);
  const dispatch = useAppDispatch();
  const onUserRequest = (isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  const onSelect = (item: TopNavigationItem) => {
    setSelectedItem(item.id);
  };

  useEffect(() => {
    if (onlyVisualSelection.current === false) {
      if (selectedItem === SPINE_MENU_ITEMS.VIEW_ORCHESTRATION) {
        navigate('/dashboard');
      } else if (selectedItem === SPINE_MENU_ITEMS.RUN_ORCHESTRATION) {
        navigate('/run-orchestrations');
      }
    } else {
      onlyVisualSelection.current = false;
    }
  }, [selectedItem]);

  useEffect(() => {
    if (navigationFromModal !== undefined) {
      onlyVisualSelection.current = true;
      dispatch(setSidebarNavigated(undefined));
      setSelectedItem(navigationFromModal);
    }
  }, [navigationFromModal]);

  const onExpand = (item: TopNavigationItem) => {
    const currentlyExpanded: string[] = [...expandedItems];
    if (currentlyExpanded.includes(item.id)) {
      const newExpanded = currentlyExpanded.filter(
        (expandedItem) => expandedItem !== item.id
      );
      setExpandedItems(newExpanded);
    } else {
      const newExpanded = currentlyExpanded.concat(item.id);
      setExpandedItems(newExpanded);
    }
  };

  const onWidthChange = (newWidth: number) => {
    newWidth > MAX_WIDTH ? setWidth(MAX_WIDTH) : setWidth(newWidth);
  };

  const onOpenBegin = () => {
    setItems(openedItems);
  };

  const onCloseEnd = () => {
    setItems(closedItems);
  };

  return (
    <CsbErrorBoundary>
      <div
        style={{ margin: '0px', height: '94.5vh' }}
        data-testid="SpineMenuCsb"
      >
        <Spine
          navigationItems={items}
          enableHoverOpen={false}
          controlMode="controlled"
          expandedItems={expandedItems}
          selectedItem={selectedItem}
          isOpen={isOpen}
          widthControlMode="controlled"
          width={width}
          onExpandClick={(event: any) => onExpand(event)}
          onItemClick={(event: any) => onSelect(event)}
          onOpenBegin={() => onOpenBegin()}
          onCloseEnd={() => onCloseEnd()}
          onUserRequest={(event: any) => onUserRequest(event)}
          onWidthChange={(event: any) => onWidthChange(event)}
          isClassicStyle={false}
        >
          {props.children}
        </Spine>
      </div>
    </CsbErrorBoundary>
  );
};

export default SpineMenuCsb;

import {
  Pagination,
  PaginationBar,
  PaginationBarLeft,
  PaginationBarRight,
} from '@wk/components-react16';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  composeFilterInfo,
  setPaginatorInfo,
} from 'app/dashboard/DashboardSlice';
import { FC, useEffect, useState } from 'react';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './PaginatorContainer.module.scss';

const PaginatorContainer: FC<any> = (props) => {
  const dispatch = useAppDispatch();
  const dashboardStatus = useAppSelector((state) => state.dashboardContainer);
  const currentStatus = dashboardStatus.selectedStatus;
  const paginatorInfo = dashboardStatus.paginatorInfo;
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setTotal(currentStatus.count || 0);
  }, [currentStatus]);

  const getTopItem = () => {
    return 1 + paginatorInfo.pageSize * (paginatorInfo.page - 1);
  };

  const getBottomItem = () => {
    return paginatorInfo.pageSize * paginatorInfo.page;
  };

  const getTotalPages = () => {
    return Math.ceil(total / paginatorInfo.pageSize);
  };

  const onPageChange = (newPage: any) => {
    dispatch(setPaginatorInfo({ ...paginatorInfo, page: newPage }));
    dispatch(composeFilterInfo());
  };

  return (
    <CsbErrorBoundary>
      <div
        className={style['paginator-container']}
        data-testid="PaginatorContainer"
      >
        <PaginationBar>
          <PaginationBarLeft>
            {getTopItem()} - {getBottomItem()} of {total} results{' '}
          </PaginationBarLeft>
          <PaginationBarRight>
            <Pagination
              pageIndex={paginatorInfo.page}
              pageCount={getTotalPages()}
              onUserRequest={(event: any) => onPageChange(event)}
            ></Pagination>
          </PaginationBarRight>
        </PaginationBar>
      </div>
    </CsbErrorBoundary>
  );
};

export default PaginatorContainer;

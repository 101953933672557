import { FC, MutableRefObject, useState } from 'react';
import { Accordion, SplitpaneAside } from '@wk/components-react16';
import { OrchestrationInstanceAccordion } from 'components/OrchestrationAccordions/OrchestrationInstanceAccordion/OrchestrationInstanceAccordion';
import { OrchestrationStepAccordion } from 'components/OrchestrationAccordions/OrchestrationStepAccordion/OrchestrationStepAccordion';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './OrchestrationPropertiesPanel.module.scss';

interface OrchestrationPropertiesPanelProps {
  containerRef: MutableRefObject<any>;
}

const OrchestrationPropertiesPanel: FC<OrchestrationPropertiesPanelProps> = ({
  containerRef,
}) => {
  const [width, setWidth] = useState<string>('25%');
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const onToggle = () => setIsOpen((isOpen) => !isOpen);

  const onWidthChange = (mouseX: number) => {
    const container = containerRef.current;
    const { left, width } = container.getBoundingClientRect();
    const withinContainer = mouseX - left;
    const widthPercentLeft = (withinContainer / width) * 100;
    const widthPercentRight = 100 - widthPercentLeft;
    const clamped = Math.min(60, Math.max(widthPercentRight, 20));
    setWidth(clamped + '%');
  };

  return (
    <CsbErrorBoundary>
      <SplitpaneAside
        position="right"
        isOpen={isOpen}
        controlMode="controlled"
        widthControlMode="controlled"
        width={width}
        onUserRequest={onToggle}
        onWidthChange={(event: number) => onWidthChange(event)}
      >
        {isOpen ? (
          <div
            data-testid="OrchestrationPropertiesPanel"
            className={style['orchestration-properties-panel']}
          >
            <div className={style['orchestration-properties-panel__header']}>
              <span
                className={
                  style['orchestration-properties-panel__header-title']
                }
              >
                Details
              </span>
              <hr
                className={
                  style['orchestration-properties-panel__header-divider']
                }
              ></hr>
            </div>
            <div className={style['orchestration-properties-panel__accordion']}>
              <Accordion>
                <OrchestrationInstanceAccordion />
                <OrchestrationStepAccordion />
              </Accordion>
            </div>
          </div>
        ) : null}
      </SplitpaneAside>
    </CsbErrorBoundary>
  );
};

export default OrchestrationPropertiesPanel;

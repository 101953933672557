import { useLazyGetWorkflowByIdQuery } from 'api/orchestrationApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import FlowChartDetails from 'components/FlowChartDetails/FlowChartDetails';
import OrchestrationHeader from 'components/OrchestrationHeader/OrchestrationHeader';
import OrchestrationPropertiesPanel from 'components/OrchestrationPropertiesPanel/OrchestrationPropertiesPanel';
import { IOrchestrationRow } from 'interfaces/dashboard/orchestration-row.interface';
import { IOrchestrationFullStep } from 'interfaces/orchestrationDiagram/orchestration-step';
import { IOrchestrationInstancePanel } from 'interfaces/orchestrationDiagram/orchestration-diagram';
import { mapJobToTableData } from 'dto/jobToTable/jobToTable';
import { createOrchestrationInstanceSidePanelStep } from 'dto/orchestrationDiagram/orchestrationInstance';
import { FC, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  generateEdges,
  getSteps,
  createNodes,
} from './reactFlow/reactFlowHelpers';
import {
  setInitialPropertiesPanelValues,
  setCurrentJob,
} from 'app/propertiesPanel/PropertiesPanelSlice';
import OrchestrationBanner from 'components/OrchestrationBanner/OrchestrationBanner';
import style from './OrchestrationDetails.module.scss';
import { useServerSideEvents } from 'utils/hooks/useSSE';
import {
  ORCHESTRATION_STATUSES,
  PAGE,
  SSE_EVENTS,
  SSE_STATUS,
} from 'utils/common-constants';
import { RootState } from 'app/store';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { consoleErrorMessage } from 'utils/commonFunctions/CommonFunctions';
import { fullStepsToJobSteps } from 'dto/orchestrationDiagram/selectedOrchestration';

const OrchestrationDetails: FC = (props: any): JSX.Element => {
  const currentJob = useAppSelector(
    (state: RootState) => state.propertiesPanel.currentJob
  );
  const [nodes, setNodes] = useState<any[]>([]);
  const [edges, setEdges] = useState<any[]>([]);
  const allSteps = useRef<any[]>([]);
  const reUpdateCurrentJob = useRef<any>(true);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [selectedOrchestration, setSelectedOrchestration] =
    useState<IOrchestrationRow>();
  const [orchestrationInstance, setOrchestrationInstance] =
    useState<IOrchestrationInstancePanel>();
  const containerRef = useRef<HTMLDivElement>(null);
  const [getWorkflowById] = useLazyGetWorkflowByIdQuery();
  const flowChartContainer = useRef<HTMLDivElement>(null);

  const { connectionStatusState } = useServerSideEvents(
    [SSE_EVENTS.JOB_STEP_UPDATE, SSE_EVENTS.JOB_STATUS_UPDATE],
    PAGE.ORCHESTRATION_DETAILS
  );

  const setOrchestrationDetails = (orchestration: any) => {
    let steps: IOrchestrationFullStep[][] = [];
    let globalFailbackSteps: any = [];
    const containerWidth =
      flowChartContainer.current?.getBoundingClientRect().width ?? 1000;
    setNodes([]);
    setEdges([]);
    const formatedOrchestration = mapJobToTableData(orchestration);
    const orchestrationInstanceStep =
      createOrchestrationInstanceSidePanelStep(orchestration);
    steps = getSteps(
      orchestration.workflow.nextStepGroup,
      steps,
      false,
      'step'
    );
    globalFailbackSteps = getSteps(
      orchestration.workflow.failbackStepGroup,
      globalFailbackSteps,
      false,
      'globalFailback'
    );
    const { nodes, orchestrarionSteps } = createNodes(
      orchestration,
      containerWidth - containerWidth * 0.25,
      [...steps, ...globalFailbackSteps]
    );
    allSteps.current = orchestrarionSteps;
    const edges = generateEdges(steps);
    const failBackedges = generateEdges(globalFailbackSteps);

    setNodes(nodes);
    setOrchestrationInstance(orchestrationInstanceStep);
    setSelectedOrchestration(formatedOrchestration);
    setEdges([...edges, ...failBackedges]);
    dispatch(
      setInitialPropertiesPanelValues({
        orchestrationInstancePanelStep: orchestrationInstanceStep,
        propertiesPanelSteps: orchestrarionSteps,
      })
    );
  };

  const getOrchestrationDetails = () => {
    const regex = /\/(\d+)(?:\?|$)/; // Matches the last numbers before either "?" or the end of the string
    const orchestrationId = location.pathname.match(regex)?.[1] ?? '';
    const fetchData = async (id: string) => {
      try {
        const orchestration = await getWorkflowById(id).unwrap();
        dispatch(setCurrentJob(orchestration));
      } catch (error) {
        consoleErrorMessage(error);
      }
    };

    fetchData(orchestrationId);
  };

  useEffect(() => {
    getOrchestrationDetails();
    return () => {};
  }, [location.pathname]);

  useEffect(() => {
    reloadIfOrchestrationCreatedFailed();
    return () => {};
  }, [location.pathname, selectedOrchestration]);

  useEffect(() => {
    if (connectionStatusState === SSE_STATUS.RESTORED) {
      getOrchestrationDetails();
    }
    return () => {};
  }, [connectionStatusState]);

  useEffect(() => {
    if (allSteps.current.length > 0) {
      reUpdateCurrentJob.current = false;
      dispatch(
        setCurrentJob({
          ...currentJob,
          steps: fullStepsToJobSteps(allSteps.current),
        })
      );
    }
    return () => {
      dispatch(setCurrentJob({} as any));
    };
  }, [allSteps.current]);

  useEffect(() => {
    if (
      currentJob &&
      Object.keys(currentJob).length > 0 &&
      reUpdateCurrentJob.current === true
    ) {
      setOrchestrationDetails(currentJob);
    } else {
      reUpdateCurrentJob.current = true;
    }
  }, [currentJob]);

  const reloadIfOrchestrationCreatedFailed = () => {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    const parameterValue = searchParams.get('refetch');
    if (
      parameterValue &&
      (selectedOrchestration?.status === ORCHESTRATION_STATUSES.FAILED ||
        selectedOrchestration?.status === ORCHESTRATION_STATUSES.STOPPED)
    ) {
      searchParams.delete('refetch');
      const newUrl = `${window.location.pathname}`;
      window.history.replaceState(null, '', newUrl);
      setTimeout(() => {
        getOrchestrationDetails();
      }, 1000);
    }
  };

  return (
    <>
      <div
        data-testid="OrchestrationDetails"
        ref={containerRef}
        className={style['orchestration-details']}
      >
        <section className={style['orchestration-details__main__section']}>
          <CsbErrorBoundary>
            <OrchestrationBanner
              selectedOrchestration={selectedOrchestration}
            />
          </CsbErrorBoundary>
          <div className={style['orchestration-details__header']}>
            <CsbErrorBoundary>
              <OrchestrationHeader
                selectedOrchestration={selectedOrchestration}
              />
            </CsbErrorBoundary>
          </div>
          <div
            className={style['orchestration-details__flow-chart']}
            data-testid="FlowChartDetails"
            ref={flowChartContainer}
          >
            <CsbErrorBoundary>
              <FlowChartDetails nodes={nodes} edges={edges} />
            </CsbErrorBoundary>
          </div>
        </section>
        {orchestrationInstance && (
          <CsbErrorBoundary>
            <OrchestrationPropertiesPanel containerRef={containerRef} />
          </CsbErrorBoundary>
        )}
      </div>
    </>
  );
};

export default OrchestrationDetails;
